import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  SelectOption,
  DefaultQueries,
  SaleTransaction,
  SaleTransactionsQueries,
  CreateSaleTransactionForm,
  SaleTransactionLot,
  Lot,
  CreateTransactionLotsForm,
  WalletAccount,
  CreateTransactionLotTransportForm,
  CreateTransactionLotShippingForm,
  CreateTransactionLotCrossDockForm,
  CreateTransactionLotDestinationForm,
  CreateTransactionLotSourceForm,
  TransactionQualityCheckForm,
  CreateSaleTransactionSyncForm,
  SaleInvoice,
} from 'models';

type Queries = DefaultQueries & SaleTransactionsQueries & {};
export type DialogId =
  | null
  | 'addLots'
  | 'paymentTransport'
  | 'paymentShipping'
  | 'paymentCrossDock'
  | 'addSourceLots'
  | 'addDestinationLots'
  | 'addQuantity'
  | 'addTransactionSync';
export type CancelPayment = null | 'transport' | 'shipping' | 'crossDock';
export interface InitialState {
  dialogId: DialogId;
  cancelPayment: CancelPayment;
  transactionsList: {
    transactions: SaleTransaction[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  transactionDetail: {
    isLoading: boolean;
    isUpdating: boolean;
    error: string | null;
    transaction: SaleTransaction | null;
    lots: SaleTransactionLot[];
    invoiceNumber: string;
    wallets: WalletAccount[];
  };
  transactionCreate: {
    isLoading: boolean;
    error: string | null;
    isCreating: boolean;
  };
  productsList: {
    isLoading: boolean;
    error: string | null;
    products: SelectOption[];
  };
  invoicesList: {
    isLoading: boolean;
    error: string | null;
    invoices: SaleInvoice[];
  };
  customersList: {
    isLoading: boolean;
    error: string | null;
    customers: SelectOption[];
  };
  addLots: {
    isLoading: boolean;
    isCreating: boolean;
    error: string | null;
    lots: Lot[];
    isAfterSubmitSuccess: boolean;
    transaction: SaleTransaction | null;
  };
  paymentLot: {
    isCreating: boolean;
    error: string | null;
    isAfterPaymentTransport: boolean;
    lot: SaleTransactionLot | null;
  };
  sync: {
    error: string | null;
    isCreating: boolean;
    isAfterSync: boolean;
  };
  updateInvoice: {
    isUpdating: boolean;
    error: null | string;
  };
  exportData: {
    isLoading: boolean;
  };
}
const initialState: InitialState = {
  dialogId: null,
  cancelPayment: null,
  transactionsList: {
    transactions: [],
    isLoading: false,
    error: null,
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
  transactionDetail: {
    isLoading: false,
    error: null,
    transaction: null,
    invoiceNumber: '',
    lots: [],
    wallets: [],
    isUpdating: false,
  },
  transactionCreate: {
    error: null,
    isCreating: false,
    isLoading: false,
  },
  productsList: {
    isLoading: false,
    error: null,
    products: [],
  },
  invoicesList: {
    isLoading: false,
    error: null,
    invoices: [],
  },
  customersList: {
    isLoading: false,
    error: null,
    customers: [],
  },
  addLots: {
    isLoading: false,
    isCreating: false,
    isAfterSubmitSuccess: false,
    error: null,
    lots: [],
    transaction: null,
  },
  paymentLot: {
    isCreating: false,
    error: null,
    isAfterPaymentTransport: false,
    lot: null,
  },
  sync: {
    isCreating: false,
    error: null,
    isAfterSync: false,
  },
  updateInvoice: {
    error: null,
    isUpdating: false,
  },
  exportData: {
    isLoading: false,
  },
};
export enum Types {
  cancelRequestAPI = 'tier/sale-transactions@cancelRequestAPI',
  onChangeDialog = 'tier/sale-transactions@onChangeDialog',
  getSaleTransactionsRequest = 'tier/sale-transactions@getSaleTransactionsRequest',
  getSaleTransactionsSuccess = 'tier/sale-transactions@getSaleTransactionsSuccess',
  getSaleTransactionsFailure = 'tier/sale-transactions@getSaleTransactionsFailure',
  getSaleTransactionRequest = 'tier/sale-transactions@getSaleTransactionRequest',
  getSaleTransactionSuccess = 'tier/sale-transactions@getSaleTransactionSuccess',
  getSaleTransactionFailure = 'tier/sale-transactions@getSaleTransactionFailure',
  onChangeTransactionsListPage = 'tier/sale-transactions@onChangeTransactionsListPage',
  onChangeTransactionsListPageSize = 'tier/sale-transactions@onChangeTransactionsListPageSize',
  openCreateSaleTransactionPage = 'tier/sale-transactions@openCreateSaleTransactionPage',
  createSaleTransactionRequest = 'tier/sale-transactions@createSaleTransactionRequest',
  createSaleTransactionSuccess = 'tier/sale-transactions@createSaleTransactionSuccess',
  createSaleTransactionFailure = 'tier/sale-transactions@createSaleTransactionFailure',
  getProductsRequest = 'tier/sale-transactions@getProductsRequest',
  getProductsSuccess = 'tier/sale-transactions@getProductsSuccess',
  getProductsFailure = 'tier/sale-transactions@getProductsFailure',
  getCustomersRequest = 'tier/sale-transactions@getCustomersRequest',
  getCustomersSuccess = 'tier/sale-transactions@getCustomersSuccess',
  getCustomersFailure = 'tier/sale-transactions@getCustomersFailure',
  getInvoicesRequest = 'tier/sale-transactions@getInvoicesRequest',
  getInvoicesSuccess = 'tier/sale-transactions@getInvoicesSuccess',
  getInvoicesFailure = 'tier/sale-transactions@getInvoicesFailure',
  getLotsRequest = 'tier/sale-transactions@getLotsRequest',
  getLotsSuccess = 'tier/sale-transactions@getLotsSuccess',
  getLotsFailure = 'tier/sale-transactions@getLotsFailure',
  openAddLotsDialog = 'tier/sale-transactions@openAddLotsDialog',
  createTransactionLotsRequest = 'tier/sale-transactions@createTransactionLotsRequest',
  createTransactionLotsSuccess = 'tier/sale-transactions@createTransactionLotsSuccess',
  createTransactionLotsFailure = 'tier/sale-transactions@createTransactionLotsFailure',
  afterCreateTransactionLotsSuccess = 'tier/sale-transactions@afterCreateTransactionLotsSuccess',
  paymentTransportRequest = 'tier/sale-transactions@paymentTransportRequest',
  paymentTransportSuccess = 'tier/sale-transactions@paymentTransportSuccess',
  paymentTransportFailure = 'tier/sale-transactions@paymentTransportFailure',
  afterPaymentTransportSuccess = 'tier/sale-transactions@afterPaymentTransportSuccess',
  openPaymentLot = 'tier/sale-transactions@openPaymentLot',
  paymentShippingRequest = 'tier/sale-transactions@paymentShippingRequest',
  paymentShippingSuccess = 'tier/sale-transactions@paymentShippingSuccess',
  paymentShippingFailure = 'tier/sale-transactions@paymentShippingFailure',
  paymentCrossDockRequest = 'tier/sale-transactions@paymentCrossDockRequest',
  paymentCrossDockSuccess = 'tier/sale-transactions@paymentCrossDockSuccess',
  paymentCrossDockFailure = 'tier/sale-transactions@paymentCrossDockFailure',
  updateSourceRequest = 'tier/sale-transactions@updateSourceRequest',
  updateSourceSuccess = 'tier/sale-transactions@updateSourceSuccess',
  updateSourceFailure = 'tier/sale-transactions@updateSourceFailure',
  updateDestinationRequest = 'tier/sale-transactions@updateDestinationRequest',
  updateDestinationSuccess = 'tier/sale-transactions@updateDestinationSuccess',
  updateDestinationFailure = 'tier/sale-transactions@updateDestinationFailure',
  addQuantityRequest = 'tier/sale-transactions@addQuantityRequest',
  addQuantitySuccess = 'tier/sale-transactions@addQuantitySuccess',
  addQuantityFailure = 'tier/sale-transactions@addQuantityFailure',
  createTransactionSyncRequest = 'tier/sale-transactions@createTransactionSyncRequest',
  createTransactionSyncSuccess = 'tier/sale-transactions@createTransactionSyncSuccess',
  createTransactionSyncFailure = 'tier/sale-transactions@createTransactionSyncFailure',
  afterCreateTransactionSyncSuccess = 'tier/sale-transactions@afterCreateTransactionSyncSuccess',
  setSelectCancelPayment = 'tier/sale-transactions@setSelectCancelPayment',
  cancelPaymentRequest = 'tier/sale-transactions@cancelPaymentRequest',
  cancelPaymentSuccess = 'tier/sale-transactions@cancelPaymentSuccess',
  cancelPaymentFailure = 'tier/sale-transactions@cancelPaymentFailure',
  updateInvoiceRequest = 'tier/sale-transactions@updateInvoiceRequest',
  updateInvoiceSuccess = 'tier/sale-transactions@updateInvoiceSuccess',
  updateInvoiceFailure = 'tier/sale-transactions@updateInvoiceFailure',
  exportCsvRequest = 'tier/sale-transactions@exportCsvRequest',
  exportCsvSuccess = 'tier/sale-transactions@exportCsvSuccess',
  exportCsvFailure = 'tier/sale-transactions@exportCsvFailure',
}
export interface GetTransactionsSuccess extends Queries {
  transactions: SaleTransaction[];
}
export type GetSaleTransactionSuccess = {
  transaction: SaleTransaction;
  wallets: WalletAccount[];
  lots: SaleTransactionLot[];
  invoiceNumber: string;
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const onChangeDialog = createAction<DialogId>(Types.onChangeDialog);
export const getSaleTransactionsRequest = createAction<Queries>(
  Types.getSaleTransactionsRequest
);
export const getSaleTransactionsSuccess = createAction<GetTransactionsSuccess>(
  Types.getSaleTransactionsSuccess
);
export const getSaleTransactionsFailure = createAction<string>(
  Types.getSaleTransactionsFailure
);
export const onChangeTransactionsListPage = createAction<Queries>(
  Types.onChangeTransactionsListPage
);
export const onChangeTransactionsListPageSize = createAction<Queries>(
  Types.onChangeTransactionsListPageSize
);
export const getSaleTransactionRequest = createAction<string>(
  Types.getSaleTransactionRequest
);
export const getSaleTransactionSuccess =
  createAction<GetSaleTransactionSuccess>(Types.getSaleTransactionSuccess);
export const getSaleTransactionFailure = createAction<string>(
  Types.getSaleTransactionFailure
);
export const openCreateSaleTransactionPage = createAction(
  Types.openCreateSaleTransactionPage
);
export const createSaleTransactionRequest =
  createAction<CreateSaleTransactionForm>(Types.createSaleTransactionRequest);
export const createSaleTransactionSuccess = createAction(
  Types.createSaleTransactionSuccess
);
export const createSaleTransactionFailure = createAction<string>(
  Types.createSaleTransactionFailure
);
export const getProductsRequest = createAction(Types.getProductsRequest);
export const getProductsSuccess = createAction<SelectOption[]>(
  Types.getProductsSuccess
);
export const getProductsFailure = createAction<string>(
  Types.getProductsFailure
);
export const getCustomersRequest = createAction(Types.getCustomersRequest);
export const getCustomersSuccess = createAction<SelectOption[]>(
  Types.getCustomersSuccess
);
export const getCustomersFailure = createAction<string>(
  Types.getCustomersFailure
);
export const getInvoicesRequest = createAction(Types.getInvoicesRequest);
export const getInvoicesSuccess = createAction<SaleInvoice[]>(
  Types.getInvoicesSuccess
);
export const getInvoicesFailure = createAction<string>(
  Types.getInvoicesFailure
);
export const openAddLotsDialog = createAction<SaleTransaction>(
  Types.openAddLotsDialog
);
export const getLotsRequest = createAction(Types.getLotsRequest);
export const getLotsSuccess = createAction<Lot[]>(Types.getLotsSuccess);
export const getLotsFailure = createAction<string>(Types.getLotsFailure);
export const createTransactionLotsRequest =
  createAction<CreateTransactionLotsForm>(Types.createTransactionLotsRequest);
export const createTransactionLotsSuccess = createAction(
  Types.createTransactionLotsSuccess
);
export const createTransactionLotsFailure = createAction<string>(
  Types.createTransactionLotsFailure
);
export const afterCreateTransactionLotsSuccess = createAction(
  Types.afterCreateTransactionLotsSuccess
);
export const paymentTransportRequest =
  createAction<CreateTransactionLotTransportForm>(
    Types.paymentTransportRequest
  );
export const paymentTransportSuccess = createAction(
  Types.paymentTransportSuccess
);
export const paymentTransportFailure = createAction<string>(
  Types.paymentTransportFailure
);
export const afterPaymentTransportSuccess = createAction(
  Types.afterPaymentTransportSuccess
);
export const openPaymentLot = createAction<{
  lot: SaleTransactionLot;
  dialogId: DialogId;
}>(Types.openPaymentLot);
export const paymentShippingRequest =
  createAction<CreateTransactionLotShippingForm>(Types.paymentShippingRequest);
export const paymentShippingSuccess = createAction(
  Types.paymentShippingSuccess
);
export const paymentShippingFailure = createAction<string>(
  Types.paymentShippingFailure
);
export const paymentCrossDockRequest =
  createAction<CreateTransactionLotCrossDockForm>(
    Types.paymentCrossDockRequest
  );
export const paymentCrossDockSuccess = createAction(
  Types.paymentCrossDockSuccess
);
export const paymentCrossDockFailure = createAction<string>(
  Types.paymentCrossDockFailure
);
export const updateSourceRequest = createAction<CreateTransactionLotSourceForm>(
  Types.updateSourceRequest
);
export const updateSourceSuccess = createAction(Types.updateSourceSuccess);
export const updateSourceFailure = createAction<string>(
  Types.updateSourceFailure
);
export const updateDestinationRequest =
  createAction<CreateTransactionLotDestinationForm>(
    Types.updateDestinationRequest
  );
export const updateDestinationSuccess = createAction(
  Types.updateDestinationSuccess
);
export const updateDestinationFailure = createAction<string>(
  Types.updateDestinationFailure
);
export const addQuantityRequest = createAction<TransactionQualityCheckForm>(
  Types.addQuantityRequest
);
export const addQuantitySuccess = createAction(Types.addQuantitySuccess);
export const addQuantityFailure = createAction<string>(
  Types.addQuantityFailure
);
export const createTransactionSyncRequest =
  createAction<CreateSaleTransactionSyncForm>(
    Types.createTransactionSyncRequest
  );
export const createTransactionSyncSuccess = createAction<SaleTransaction>(
  Types.createTransactionSyncSuccess
);
export const createTransactionSyncFailure = createAction<string>(
  Types.createTransactionSyncFailure
);
export const afterCreateTransactionSyncSuccess = createAction(
  Types.afterCreateTransactionSyncSuccess
);
export const setSelectCancelPayment = createAction<CancelPayment>(
  Types.setSelectCancelPayment
);
export const cancelPaymentRequest = createAction(Types.cancelPaymentRequest);
export const cancelPaymentSuccess = createAction<GetSaleTransactionSuccess>(
  Types.cancelPaymentSuccess
);
export const cancelPaymentFailure = createAction<string>(
  Types.cancelPaymentFailure
);
export const updateInvoiceRequest = createAction(Types.updateInvoiceRequest);
export const updateInvoiceSuccess = createAction<
  string,
  Types.updateInvoiceSuccess
>(Types.updateInvoiceSuccess);
export const updateInvoiceFailure = createAction<
  string,
  Types.updateInvoiceFailure
>(Types.updateInvoiceFailure);
export const exportCsvRequest = createAction<Queries>(Types.exportCsvRequest);
export const exportCsvSuccess = createAction(Types.exportCsvSuccess);
export const exportCsvFailure = createAction(Types.exportCsvFailure);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(cancelRequestAPI, (state) => {
      return { ...state, isLoading: false, isCreating: false };
    })
    .addCase(onChangeDialog, (state, action) => {
      return { ...state, dialogId: action.payload };
    })
    .addCase(getSaleTransactionsRequest, (state) => {
      state.transactionsList.isLoading = true;
      state.transactionsList.error = null;
    })
    .addCase(getSaleTransactionsSuccess, (state, action) => {
      return {
        ...state,
        transactionsList: {
          ...state.transactionsList,
          ...action.payload,
          isLoading: false,
        },
      };
    })
    .addCase(getSaleTransactionsFailure, (state, action) => {
      state.transactionsList.isLoading = false;
      state.transactionsList.error = action.payload;
    })
    .addCase(createSaleTransactionRequest, (state) => {
      state.transactionCreate.isCreating = true;
      state.transactionCreate.error = null;
    })
    .addCase(createSaleTransactionSuccess, (state) => {
      state.transactionCreate.isCreating = false;
    })
    .addCase(createSaleTransactionFailure, (state, action) => {
      state.transactionCreate.isCreating = false;
      state.transactionCreate.error = action.payload;
    })
    .addCase(getProductsRequest, (state) => {
      state.productsList.isLoading = true;
      state.productsList.error = null;
    })
    .addCase(getProductsSuccess, (state, action) => {
      state.productsList.isLoading = false;
      state.productsList.products = action.payload;
    })
    .addCase(getProductsFailure, (state, action) => {
      state.productsList.isLoading = false;
      state.productsList.error = action.payload;
    })
    .addCase(getCustomersRequest, (state) => {
      state.customersList.isLoading = true;
      state.customersList.error = null;
    })
    .addCase(getCustomersSuccess, (state, action) => {
      state.customersList.isLoading = false;
      state.customersList.customers = action.payload;
    })
    .addCase(getCustomersFailure, (state, action) => {
      state.customersList.isLoading = false;
      state.customersList.error = action.payload;
    })
    .addCase(getInvoicesRequest, (state) => {
      state.invoicesList.isLoading = true;
      state.invoicesList.error = null;
    })
    .addCase(getInvoicesSuccess, (state, action) => {
      state.invoicesList.isLoading = false;
      state.invoicesList.invoices = action.payload;
    })
    .addCase(getInvoicesFailure, (state, action) => {
      state.invoicesList.isLoading = false;
      state.invoicesList.error = action.payload;
    })
    .addCase(getSaleTransactionRequest, (state) => {
      state.transactionDetail.isLoading = true;
      state.transactionDetail.error = null;
    })
    .addCase(getSaleTransactionSuccess, (state, action) => {
      state.transactionDetail.isLoading = false;
      state.transactionDetail.transaction = action.payload.transaction;
      state.transactionDetail.lots = action.payload.lots;
      state.transactionDetail.wallets = action.payload.wallets;
      state.transactionDetail.invoiceNumber = action.payload.invoiceNumber;
    })
    .addCase(getSaleTransactionFailure, (state, action) => {
      state.transactionDetail.isLoading = false;
      state.transactionDetail.error = action.payload;
    })
    .addCase(openAddLotsDialog, (state, { payload }) => {
      state.addLots.error = null;
      state.addLots.isLoading = true;
      state.addLots.transaction = payload;
      state.dialogId = 'addLots';
    })
    .addCase(getLotsRequest, (state) => {
      state.addLots.isLoading = true;
      if (state.addLots.lots.length !== 0) {
        state.addLots.isLoading = false;
      }
    })
    .addCase(getLotsSuccess, (state, { payload }) => {
      state.addLots.isLoading = false;
      state.addLots.lots = payload;
    })
    .addCase(getLotsFailure, (state, { payload }) => {
      state.addLots.isLoading = false;
      state.addLots.error = payload;
    })
    .addCase(createTransactionLotsRequest, (state) => {
      state.addLots.isLoading = true;
      state.addLots.error = null;
    })
    .addCase(createTransactionLotsSuccess, (state) => {
      state.addLots.isLoading = false;
      state.dialogId = null;
      state.addLots.isAfterSubmitSuccess = true;
    })
    .addCase(createTransactionLotsFailure, (state, { payload }) => {
      state.addLots.isLoading = false;
      state.addLots.error = payload;
    })
    .addCase(afterCreateTransactionLotsSuccess, (state) => {
      state.addLots.isLoading = false;
      state.addLots.lots = [];
      state.addLots.transaction = null;
      state.addLots.isAfterSubmitSuccess = false;
      state.dialogId = null;
    })
    .addCase(openPaymentLot, (state, { payload }) => {
      state.paymentLot.lot = payload.lot;
      state.dialogId = payload.dialogId;
    })
    .addCase(paymentTransportRequest, (state) => {
      state.paymentLot.isCreating = true;
      state.paymentLot.error = null;
      state.paymentLot.isAfterPaymentTransport = false;
    })
    .addCase(paymentTransportSuccess, (state) => {
      state.paymentLot.isCreating = false;
      state.paymentLot.isAfterPaymentTransport = true;
      state.dialogId = null;
    })
    .addCase(paymentTransportFailure, (state, { payload }) => {
      state.paymentLot.isCreating = false;
      state.paymentLot.error = payload;
    })
    .addCase(afterPaymentTransportSuccess, (state) => {
      state.paymentLot.isCreating = false;
      state.paymentLot.isAfterPaymentTransport = false;
      state.dialogId = null;
    })
    .addCase(paymentShippingRequest, (state) => {
      state.paymentLot.isCreating = true;
      state.paymentLot.error = null;
    })
    .addCase(paymentShippingSuccess, (state) => {
      state.paymentLot.isCreating = false;
      state.dialogId = null;
    })
    .addCase(paymentShippingFailure, (state, { payload }) => {
      state.paymentLot.isCreating = false;
      state.paymentLot.error = payload;
    })
    .addCase(paymentCrossDockRequest, (state) => {
      state.paymentLot.isCreating = true;
      state.paymentLot.error = null;
    })
    .addCase(paymentCrossDockSuccess, (state) => {
      state.paymentLot.isCreating = false;
      state.dialogId = null;
    })
    .addCase(paymentCrossDockFailure, (state, { payload }) => {
      state.paymentLot.isCreating = false;
      state.paymentLot.error = payload;
    })
    .addCase(updateSourceRequest, (state) => {
      state.paymentLot.isCreating = true;
      state.paymentLot.error = null;
    })
    .addCase(updateSourceSuccess, (state) => {
      state.paymentLot.isCreating = false;
      state.dialogId = null;
    })
    .addCase(updateSourceFailure, (state, { payload }) => {
      state.paymentLot.isCreating = false;
      state.paymentLot.error = payload;
    })
    .addCase(updateDestinationRequest, (state) => {
      state.paymentLot.isCreating = true;
      state.paymentLot.error = null;
    })
    .addCase(updateDestinationSuccess, (state) => {
      state.paymentLot.isCreating = false;
      state.dialogId = null;
    })
    .addCase(updateDestinationFailure, (state, { payload }) => {
      state.paymentLot.isCreating = false;
      state.paymentLot.error = payload;
    })
    .addCase(addQuantityRequest, (state) => {
      state.paymentLot.isCreating = true;
      state.paymentLot.error = null;
    })
    .addCase(addQuantitySuccess, (state) => {
      state.paymentLot.isCreating = false;
      state.dialogId = null;
    })
    .addCase(addQuantityFailure, (state, { payload }) => {
      state.paymentLot.isCreating = false;
      state.paymentLot.error = payload;
    })
    .addCase(createTransactionSyncRequest, (state) => {
      state.sync.isCreating = true;
      state.sync.error = null;
    })
    .addCase(createTransactionSyncSuccess, (state, { payload }) => {
      state.sync.isCreating = false;
      state.dialogId = null;
      state.transactionsList.transactions =
        state.transactionsList.transactions.map((transaction) => {
          if (transaction.id === payload.id) {
            return payload;
          }
          return transaction;
        });
    })
    .addCase(createTransactionSyncFailure, (state, { payload }) => {
      state.sync.isCreating = false;
      state.sync.error = payload;
    })
    .addCase(afterCreateTransactionSyncSuccess, (state) => {
      state.sync.isAfterSync = false;
    })
    .addCase(setSelectCancelPayment, (state, { payload }) => {
      state.cancelPayment = payload;
    })
    .addCase(cancelPaymentRequest, (state) => {
      state.transactionDetail.isUpdating = true;
      state.transactionDetail.error = null;
    })
    .addCase(cancelPaymentSuccess, (state, action) => {
      state.transactionDetail.isUpdating = false;
      state.cancelPayment = null;
      state.transactionDetail = {
        ...state.transactionDetail,
        ...action.payload,
      };
    })
    .addCase(cancelPaymentFailure, (state, { payload }) => {
      state.transactionDetail.isUpdating = false;
      state.transactionDetail.error = payload;
    })
    .addCase(updateInvoiceRequest, (state) => {
      state.updateInvoice.isUpdating = true;
      state.updateInvoice.error = null;
    })
    .addCase(updateInvoiceSuccess, (state) => {
      state.updateInvoice.isUpdating = false;
    })
    .addCase(updateInvoiceFailure, (state, { payload }) => {
      state.updateInvoice.isUpdating = false;
      state.updateInvoice.error = payload;
    });
  builder
    .addCase(exportCsvRequest, (state) => {
      state.exportData.isLoading = true;
    })
    .addCase(exportCsvSuccess, (state) => {
      state.exportData.isLoading = false;
    })
    .addCase(exportCsvFailure, (state) => {
      state.exportData.isLoading = false;
    });
});

export default reducer;
