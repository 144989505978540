import { all, fork, take } from 'redux-saga/effects';
import auth from './auth/saga';
import me from './me/saga';
import adminProducts from './admin-products/saga';
import adminTiers from './super-admin/tiers/saga';
import tier from './tier/saga';
import admin from './admin/saga';
import data from './data/saga';
import superAdmin from './super-admin/saga';

function* watchGetData() {
  yield take('authorizeSuccess');
  yield all([
    fork(me),
    fork(adminProducts),
    fork(adminTiers),
    fork(tier),
    fork(admin),
    fork(superAdmin),
    fork(data),
  ]);
}

export default function* sagas() {
  yield all([fork(auth), fork(watchGetData)]);
}
