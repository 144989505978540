import {
  all,
  fork,
  put,
  call,
  takeLeading,
  takeLatest,
  select,
} from 'redux-saga/effects';
import moment from 'moment';
import { push } from 'redux-first-history';
import API, { CancelToken, Canceler } from 'services/defaultInstance';
import * as actions from './index';
import {
  GetTiersResponse,
  CreateTier,
  GetTierResponse,
  GetUsersResponse,
  User,
  WalletAccount,
  GetWalletAccountsResponse,
  GetWalletAccountsAdminResponse,
  GetZonesResponse,
} from 'models';
import {
  findErrorToData,
  getAllDataWithCursorNextPage,
  provinces,
} from 'utils';
import { addAlert } from 'store/notify';
import { RootState } from 'store';
import { addTierToUser as setTierToUser } from 'store/auth';
let cancels: Canceler[] = [];

function* getTiers({ payload }: ReturnType<typeof actions.getTiersRequest>) {
  const {
    tiersList: { pageTokens, pageSize },
  }: actions.InitialState = yield select((state) => state.superAdmin.tiers);
  let query = `pageSize=${payload.pageSize ? payload.pageSize : pageSize}`;
  if (payload.pageToken) {
    query += `&pageToken=${payload.pageToken}`;
  }
  if (payload.createdAt) {
    query += `&startAt=${encodeURIComponent(
      moment(payload.createdAt).format('YYYY-MM-DDTHH:mm:ssZ')
    )})}`;
  }
  let dataPayload: actions.GetTiersSuccess = {
    tiers: [],
    pageTokens: [...pageTokens],
  };
  try {
    const { data }: GetTiersResponse = yield call(
      API.get,
      `/v1/admin/tier/tiers?${query}`
    );
    dataPayload.tiers = data.tiers;
    const pageToken = pageTokens.find((p) => p === data.nextPageToken);
    if (
      !pageToken &&
      dataPayload.pageTokens &&
      data.nextPageToken.length !== 0
    ) {
      dataPayload.pageTokens.push(data.nextPageToken);
    }
    if (payload.page) {
      dataPayload.page = payload.page;
    }
    if (payload.pageSize && payload.pageSize !== pageSize) {
      dataPayload.pageTokens =
        data.nextPageToken.length === 0 ? [] : [data.nextPageToken];
      dataPayload.pageSize = payload.pageSize;
      dataPayload.page = 1;
    }
    yield put(actions.getTiersSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getTiersFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeTiersPage({
  payload,
}: ReturnType<typeof actions.onChangeTiersPage>) {
  const {
    tiersList: { pageTokens, page },
  }: actions.InitialState = yield select((state) => state.superAdmin.tiers);
  let cloneFilters: any = {
    ...payload,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  cloneFilters.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    cloneFilters.pageToken = pageToken;
  }
  yield put(actions.getTiersRequest(cloneFilters));
}
function* createTier({
  payload,
}: ReturnType<typeof actions.createTierRequest>) {
  const province = provinces.find((p) => p.label === payload.province);
  const tier: CreateTier = {
    address: {
      ...payload.address,
      province: payload.province,
    },
    name: payload.name,
    code: payload.code,
    emailAddresses: payload.emailAddresses,
    province: province ? province.nameEn.toLowerCase() : payload.province,
    title: payload.title,
    zone: payload.zone,
    phoneNumbers: payload.phoneNumbers.split(','),
    id: payload.id,
  };
  try {
    yield call(API.post, `/v1/admin/tier/tiers`, tier, {
      cancelToken: new CancelToken((c) => cancels.push(c)),
    });
    yield put(actions.createTierSuccess());
    cancels = [];
    yield put(push('/super-admin/tiers'));
    yield put(
      addAlert({
        message: 'ສ້າງລານສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createTierFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
//FIXME: get tier and users
function* getTierUsers({
  payload,
}: ReturnType<typeof actions.getTierUsersRequest>) {
  try {
    const {
      auth: {
        accept: { resourceAccess },
      },
    }: RootState = yield select((state) => state);
    const getTier: GetTierResponse = yield call(
      API.get,
      `/v1/admin/tier/tiers/${payload}`
    );
    let users: User[] = [];
    if (resourceAccess['admin-users'].read) {
      const getUsers: GetUsersResponse = yield call(
        API.get,
        `/v1/admin/tier/tiers/${payload}/users?pageSize=250`
      );
      users = getUsers.data.users;
    }
    yield put(
      actions.getTierUsersSuccess({
        tier: getTier.data.tier,
        users: users,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getTierUsersFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* openDialogAddTierToUser() {
  const {
    auth,
    superAdmin: {
      tiers: { usersList },
    },
  }: RootState = yield select((state) => state);
  if (
    usersList.users.length === 0 &&
    auth.accept.resourceAccess['admin-users'].read
  ) {
    yield put(actions.getUsersListRequest());
  }
}
//FIXME: get users pageSize 250
function* getUsersList() {
  try {
    const entries: User[] = yield getAllDataWithCursorNextPage<User[], 'users'>(
      {
        httpRequest: (queries) =>
          API.get(`/v1/admin/tier/users${queries}`, {
            params: {},
          }),
        keyResponse: 'users',
      }
    );
    yield put(actions.getUsersListSuccess(entries));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getUsersListFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
//FIXME: get wallet accounts pageSize 250
function* getTierWallets({
  payload,
}: ReturnType<typeof actions.getTierWalletsRequest>) {
  try {
    const {
      auth: {
        accept: { resourceAccess },
      },
    }: RootState = yield select((state) => state);
    const getTier: GetTierResponse = yield call(
      API.get,
      `/v1/admin/tier/tiers/${payload}`
    );
    let accounts: WalletAccount[] = [];
    if (resourceAccess['admin-wallets'].read) {
      const getWallets: GetWalletAccountsResponse = yield call(
        API.get,
        `/v1/admin/tier/tiers/${payload}/wallets?pageSize=250`
      );
      accounts = getWallets.data.wallets;
    }
    yield put(
      actions.getTierWalletsSuccess({
        tier: getTier.data.tier,
        wallets: accounts,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getTierWalletsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* addTierToUser({
  payload,
}: ReturnType<typeof actions.addTierToUserRequest>) {
  try {
    const {
      superAdmin: {
        tiers: {
          tierUsers: { tier },
          usersList: { users },
        },
      },
      auth: {
        accept: { profile },
      },
    }: RootState = yield select((state) => state);
    yield call(API.post, `/v1/admin/tier/tiers/users`, {
      username: payload.username,
      tier: tier?.name || '',
    });
    yield put(actions.addTierToUserSuccess());
    yield put(actions.getTierUsersRequest(tier?.name || ''));
    const user = users.find((user) => user.username === payload.username);
    if (user) {
      yield put(
        addAlert({
          message: 'ເພີ່ມສຳເລັດ',
          serviceType: 'snackbar',
          type: 'success',
        })
      );
      yield put(actions.stUserToTierUsers(user));
    }
    if (payload.username === profile?.username && tier) {
      yield put(setTierToUser(tier));
    }
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.addTierToUserFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* openDialogAddWalletToTier() {
  const {
    auth,
    superAdmin: {
      tiers: { walletsList },
    },
  }: RootState = yield select((state) => state);
  if (
    walletsList.wallets.length === 0 &&
    auth.accept.resourceAccess['admin-wallets'].read
  ) {
    yield put(actions.getWalletsListRequest());
  }
}
function* getWalletsList() {
  try {
    const { data }: GetWalletAccountsAdminResponse = yield call(
      API.get,
      `/v1/admin/wallet/accounts?pageSize=250`
    );
    yield put(actions.getWalletsListSuccess(data.accounts));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getWalletsListFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* addWalletToTier({
  payload,
}: ReturnType<typeof actions.addWalletToTierRequest>) {
  try {
    const {
      tierWallets: { tier },
      walletsList: { wallets },
    }: actions.InitialState = yield select((state) => state.superAdmin.tiers);
    yield call(API.post, `/v1/admin/tier/tiers/wallets`, {
      walletNumber: payload.walletNumber,
      tier: tier?.name || '',
    });
    yield put(actions.addWalletToTierSuccess());
    yield put(actions.getTierWalletsRequest(tier?.name || ''));
    const wallet = wallets.find(
      (wallet) => wallet.number === payload.walletNumber
    );
    if (wallet) {
      yield put(
        addAlert({
          message: 'ເພີ່ມສຳເລັດ',
          serviceType: 'snackbar',
          type: 'success',
        })
      );
      yield put(actions.setWalletToTierWallets(wallet));
    }
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.addWalletToTierFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* openPageCreateTier() {
  const {
    auth: {
      accept: { resourceAccess },
    },
  }: RootState = yield select((state) => state);
  let zones: any = [];
  try {
    if (resourceAccess['admin-zones'].read) {
      const zonesReq: GetZonesResponse = yield call(
        API.get,
        '/v1/admin/tier/zones?pageSize=250'
      );
      zones = zonesReq.data.zones;
    }
    yield put(
      actions.openPageCreateTierSuccess({
        zones: zones,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.openPageCreateTierFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchOpenDialogAddTierToUser() {
  yield takeLatest(
    actions.Types.openDialogAddTierToUser,
    openDialogAddTierToUser
  );
}
function* watchGetTiers() {
  yield takeLeading(actions.Types.getTiersRequest, getTiers);
}
function* watchOnChangeTiersPage() {
  yield takeLatest(actions.Types.onChangeTiersPage, onChangeTiersPage);
}
function* watchOnChangeTiersPageSize() {
  yield takeLatest(
    actions.Types.onChangeTiersPageSize,
    function* ({ payload }: ReturnType<typeof actions.onChangeTiersPageSize>) {
      yield put(actions.getTiersRequest(payload));
    }
  );
}
function* watchCreateTier() {
  yield takeLeading(actions.Types.createTierRequest, createTier);
}
function* watchGetTierUsers() {
  yield takeLeading(actions.Types.getTierUsersRequest, getTierUsers);
}
function* watchGetTierWallets() {
  yield takeLeading(actions.Types.getTierWalletsRequest, getTierWallets);
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetUsersList() {
  yield takeLatest(actions.Types.getUsersListRequest, getUsersList);
}
function* watchAddTierToUser() {
  yield takeLatest(actions.Types.addTierToUserRequest, addTierToUser);
}
function* watchOpenDialogAddWalletToTier() {
  yield takeLatest(
    actions.Types.openDialogAddWalletToTier,
    openDialogAddWalletToTier
  );
}
function* watchOpenPageCreateTier() {
  yield takeLatest(actions.Types.openPageCreateTierRequest, openPageCreateTier);
}
function* watchGetWalletsList() {
  yield takeLatest(actions.Types.getWalletsListRequest, getWalletsList);
}
function* watchAddWalletToTier() {
  yield takeLatest(actions.Types.addWalletToTierRequest, addWalletToTier);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetTiers),
    fork(watchCreateTier),
    fork(watchOnChangeTiersPage),
    fork(watchOnChangeTiersPageSize),
    fork(watchGetTierUsers),
    fork(watchOpenDialogAddTierToUser),
    fork(watchGetUsersList),
    fork(watchAddTierToUser),
    fork(watchGetTierWallets),
    fork(watchOpenDialogAddWalletToTier),
    fork(watchGetWalletsList),
    fork(watchAddWalletToTier),
    fork(watchOpenPageCreateTier),
  ]);
}
export default saga;
