import {
  all,
  fork,
  put,
  call,
  takeLatest,
  select,
  take,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { addAlert } from 'store/notify';
import API, { Canceler, createCancelToken } from 'services/defaultInstance';
import * as actions from './index';
import {
  GetStocksResponse,
  CreateStockQualityCheck,
  GetStockResponse,
  GetStockQualityChecksResponse,
} from 'models';
import { findErrorToData } from 'utils';
import { RootState } from 'store/index';

let cancels: Canceler[] = [];

type GetStocksRequest = ReturnType<typeof actions.getStocksRequest>;
function* getStocks({ payload }: GetStocksRequest) {
  const {
    auth,
    inventoryStocksTransform: { list },
  }: RootState = yield select((state) => state);
  let query = `status=TRANSFORMING&tier=${
    auth.accept.tier?.name || ''
  }&pageSize=${payload.pageSize ? payload.pageSize : list.pageSize}`;
  if (payload.pageToken) {
    query += `&pageToken=${payload.pageToken}`;
  }
  let dataPayload: actions.GetStocksSuccess = {
    stocks: [],
    pageTokens: [...list.pageTokens],
  };
  try {
    const { data }: GetStocksResponse = yield call(
      API.get,
      `/v1/inventory/stocks?${query}`
    );
    dataPayload.stocks = data.stocks;
    const pageToken = list.pageTokens.find((p) => p === data.nextPageToken);
    if (
      !pageToken &&
      dataPayload.pageTokens &&
      data.nextPageToken.length !== 0
    ) {
      dataPayload.pageTokens.push(data.nextPageToken);
    }
    if (payload.page) {
      dataPayload.page = payload.page;
    }
    if (payload.pageSize && payload.pageSize !== list.pageSize) {
      dataPayload.pageTokens =
        data.nextPageToken.length === 0 ? [] : [data.nextPageToken];
      dataPayload.pageSize = payload.pageSize;
      dataPayload.page = 1;
    }
    yield put(actions.getStocksSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getStocksFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type CreateQualityCheckRequest = ReturnType<
  typeof actions.createQualityCheckRequest
>;
function* createQualityCheck({ payload }: CreateQualityCheckRequest) {
  const {
    inventoryStocksTransform: { get },
  }: RootState = yield select((state) => state);
  const cancelToken: Canceler = yield createCancelToken();
  cancels.push(cancelToken);

  const data: CreateStockQualityCheck = {
    remark: payload.remark || '',
    impurity: payload.impurity,
    moisturePercent: payload.moisturePercent,
    starchPercent: payload.starchPercent,
    stockId: get.stock?.id || '',
  };
  try {
    yield call(API.post, `v1/inventory/stocks-qualityCheck`, data);
    yield put(actions.createQualityCheckSuccess(data));
    yield put(
      addAlert({
        message: 'ສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    cancels = [];
  } catch (error: any) {
    cancels = [];
    const errorData = findErrorToData({ error: error });
    yield put(actions.createQualityCheckFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getStock(stockId: string) {
  try {
    const { data }: GetStockResponse = yield call(
      API.get,
      `/v1/inventory/stocks/${stockId}`
    );
    yield put(actions.getStockSuccess(data.stock));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getStockFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getQualityChecks(stockId: string) {
  try {
    const { data }: GetStockQualityChecksResponse = yield call(
      API.get,
      `/v1/inventory/stocks-qualityCheck?stockId=${stockId}&pageSize=250`
    );
    yield put(actions.getQualityChecksSuccess(data.stocksQualityCheck));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getQualityChecksFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type GetStockRequest = ReturnType<typeof actions.getStockRequest>;
function* getStockAndQualityChecks({ payload }: GetStockRequest) {
  yield fork(getStock, payload);
  yield take(actions.Types.getStockSuccess);
  yield fork(getQualityChecks, payload);
}
type CreateCompleteTransformRequest = ReturnType<
  typeof actions.createCompleteTransformRequest
>;
function* createCompleteTransform({ payload }: CreateCompleteTransformRequest) {
  const {
    router: { location },
  }: RootState = yield select((state) => state);
  const cancelToken: Canceler = yield createCancelToken();
  cancels.push(cancelToken);
  try {
    yield call(API.post, `v1/inventory/stocks-completeTransformation`, {
      stockId: payload,
    });
    yield put(actions.createCompleteTransformSuccess());
    yield put(
      addAlert({
        message: 'ສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    cancels = [];
    yield put(
      push({ pathname: (location?.pathname || '').replace(`/${payload}`, '') })
    );
  } catch (error: any) {
    cancels = [];
    const errorData = findErrorToData({ error: error });
    yield put(actions.createCompleteTransformFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetStocks() {
  yield takeLatest(actions.Types.getStocksRequest, getStocks);
}
function* watchCreateQualityCheck() {
  yield takeLatest(actions.Types.createQualityCheckRequest, createQualityCheck);
}
function* watchGetStockAndQualityChecks() {
  yield takeLatest(actions.Types.getStockRequest, getStockAndQualityChecks);
}
function* watchCreateCompleteTransform() {
  yield takeLatest(
    actions.Types.createCompleteTransformRequest,
    createCompleteTransform
  );
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetStocks),
    fork(watchCreateQualityCheck),
    fork(watchGetStockAndQualityChecks),
    fork(watchCreateCompleteTransform),
  ]);
}
export default saga;
