import { lazy } from 'react';

export * as Report from './report';
export * as Dashboards from './dashboards';

export const ActivityLogging = lazy(() => import('./ActivityLogging'));
export const Tiers = lazy(() => import('./tiers/Tiers'));
export const TierCreate = lazy(() => import('./tiers/TierCreate'));
export const TierUsers = lazy(() => import('./tiers/Users'));
export const TierWallets = lazy(() => import('./tiers/Wallets'));
export const Products = lazy(() => import('./Products'));
export const Users = lazy(() => import('./users/Users'));
export const UserCreate = lazy(() => import('./users/UserCreate'));
export const UserUpdate = lazy(() => import('./users/UserUpdate'));
export const ExchangeRates = lazy(() => import('./ExchangeRates'));
export const Roles = lazy(() => import('./roles/Roles'));
export const RoleCreate = lazy(() => import('./roles/RoleCreate'));
export const RoleUpdate = lazy(() => import('./roles/RoleUpdate'));
export const Seasons = lazy(() => import('./seasons/Seasons'));
export const Wallets = lazy(() => import('./wallets/Wallets'));
export const Zones = lazy(() => import('./zones/Zones'));
export const Company = lazy(() => import('./Company'));
export const Customers = lazy(() => import('./Customers'));
export const ExpenseTypes = lazy(() => import('./expense-types/ExpenseTypes'));
export const Predict = lazy(() => import('./Predict'));
export const MoistureProductPrice = lazy(() => import('./product-prices'));
export const ProductPricePendingAndApprove = lazy(
  () => import('./product-prices/PendingAndApprove')
);
export const ProductPriceUnApproveAndReject = lazy(
  () => import('./product-prices/UnApproveAndReject')
);
