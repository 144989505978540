import axios, { AxiosRequestConfig, Canceler as AxiosCanceler } from 'axios';
// import mockUpData from './mock-data';
import { mappingErrorCode } from 'utils';
import { refreshToken } from './publicInstance';
import { history } from 'store/index';
const baseURL = process.env.REACT_APP_API_URL || '';
const baseInstance = axios.create({
  baseURL: baseURL,
  headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
});
baseInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers = {
        'Authorization': `Bearer ${accessToken}`,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
baseInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    const errorCode = mappingErrorCode(error);

    if (!originalConfig._retry && errorCode && errorCode === 'TOKEN_EXPIRED') {
      originalConfig._retry = true;
      const accessToken = await refreshToken();
      if (accessToken) {
        originalConfig.headers.Authorization = `Bearer ${accessToken}`;
        return baseInstance.request(originalConfig);
      }
      history.push('/login');
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
// if (process.env.REACT_APP_IS_MOCK_UP) {
//   mockUpData(baseInstance);
// }
//create CancelToken
export const CancelToken = axios.CancelToken;
export const createCancelToken = () => {
  return new CancelToken((cancel: AxiosCanceler) => cancel);
};
export type Canceler = AxiosCanceler;
export default baseInstance;
