import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API, { Canceler, CancelToken } from 'services/defaultInstance';
import * as actions from './index';
import { GetProductsResponse } from 'models';
import { findErrorToData } from 'utils';

let cancels: Canceler[] = [];
function* getProducts() {
  try {
    const { data }: GetProductsResponse = yield call(API.get, `/v1/products`, {
      cancelToken: new CancelToken((c) => cancels.push(c)),
    });
    cancels = [];
    yield put(actions.getProductsSuccess(data.products));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getProductsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetProducts() {
  yield takeLatest(actions.Types.getProductsRequest, getProducts);
}
function* saga() {
  yield all([fork(watchCancelRequestAPI), fork(watchGetProducts)]);
}
export default saga;
