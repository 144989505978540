import {
  NotifyAlert,
  Resource,
  Permission,
  CreateRoleForm,
  ResourcesCreate,
  PermissionCreate,
} from 'models';
import type { LotStatus } from 'models/lots/Repository';
import navigations, {
  NavigationItem,
  Navigation,
  NavigationKey,
} from 'config/navigations';
import type { Router } from 'config/routes';
import { AxiosError, AxiosResponse } from 'axios';
const removeSemicolon = (value: string | number) => {
  if (typeof value === 'number') return value;
  return Number.parseFloat(value.replace(',', ''));
};
export const numberFormat = (value: number | string) => {
  if (
    value.toString() === 'NaN' ||
    value === '' ||
    value === 0 ||
    value === '0'
  )
    return '0';
  return new Intl.NumberFormat().format(removeSemicolon(value)).toString();
};
interface MappingErrorToDataProps {
  error: any;
  serviceType?: 'snackbar' | 'pageComponent';
  service?: string;
}
export const findErrorToData = ({
  error,
  service,
  serviceType = 'snackbar',
}: MappingErrorToDataProps): NotifyAlert | null => {
  let data: NotifyAlert = {
    message: 'ເກີດບັນຫາບາງຢ່າງ,ກະລຸນາລອງໃຫມ່ອີກຄັ້ງ',
    serviceType: serviceType,
    type: 'error',
  };
  if (Object.keys(error).length === 0) {
    return null;
  }
  if (!error) {
    return data;
  }
  const { code, message, response } = error;
  if (!code || !message) return data;
  if (code === 'ERR_CANCELED' && message === 'canceled') {
    return null;
  }

  if (!response) return data;
  if (
    code === 'ERR_NETWORK' &&
    message === 'Network Error' &&
    response &&
    response.data === undefined
  )
    return {
      ...data,
      message: 'ການເຊື່ອມຕໍ່ມີບັນຫາ,ກະລຸນາຕິດຕໍ່ຜູ້ຮັບຜິດຊອບ',
    };
  if (response && typeof response.data !== 'object') {
    return {
      ...data,
      message: 'ຮູບແບບຂໍ້ມູນແຈ້ງຂໍ້ຜິດພາດບໍ່ຖືກຕ້ອງກະລຸນາຕິດຕໍ່ຜູ້ຮັບຜິດຊອບ',
    };
  }
  if (response.status === 500 && !service) {
    return {
      ...data,
      message: 'ເກີດບັນຫາບາງຢ່າງ,ກະລຸນາລອງໃຫມ່ອີກຄັ້ງ ຫລື ຕິດຕໍ່ຜູ້ຮັບຜິດຊອບ',
    };
  }
  if (response.data.message && response.data.message === 'Invalid request') {
    return {
      ...data,
      message: 'ຄຳຂໍບໍ່ຖືກຕ້ອງ',
    };
  }
  if (response.data.error.message === 'Credentials not valid.') {
    return {
      ...data,
      message: 'ຊື່ຜູ້ໃຊ້ ຫລື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ',
    };
  }
  if (response.data.error.message === 'Purchase price not found.') {
    return {
      ...data,
      message: 'ບໍ່ພົບລາຄາຮັບຊື້ຂອງມື້ນີ້,ກະລຸນາຕັ້ງລາຄາຮັບຊື້ຂອງມື້ນີ້',
    };
  }
  if (response.data.error.message === 'Transaction summary already exists.') {
    return {
      ...data,
      message: 'ປິດການຮັບຊື້ສຳເລັດແລ້ວ',
    };
  }
  if (response.data.error.message === 'Product mismatch.') {
    return {
      ...data,
      message: 'ກະລຸນາເລືອກສິນຄ້າໃຫ້ຖືກຕ້ອງຕາມໃບຮຽກເກັບເງິນ.',
    };
  }
  if (response.data.error.message === 'Stock should have lot id.') {
    return {
      ...data,
      message: 'ກະລຸນາເພີ່ມເຂົ້າລ໋ອດກ່ອນການແປຮູບ.',
    };
  }
  if (response.data.error.message === 'Wallet already assigned to tier.') {
    return {
      ...data,
      message: 'ກະເປົາເງິນຖືກໃຊ້ແລ້ວ.',
    };
  }
  if (response.data.error.message === 'User already exist.') {
    return {
      ...data,
      message: 'ບັນຊີຜູ້ໃຊ້ຖືກນຳໃຊ້ແລ້ວ.',
    };
  }
  if (response.data.error.message === 'Role already exist.') {
    return {
      ...data,
      message: 'ຊື່ຖືກນຳໃຊ້ແລ້ວ.',
    };
  }
  if (response.data.error.message === 'Zone mismatch.') {
    return {
      ...data,
      message: 'ຊື່ໂຊນບໍ່ກົງກັນ.',
    };
  }
  if (response.data.error.message === 'Tier already exist.') {
    return {
      ...data,
      message: 'ລະຫັດລານຖືກນຳໃຊ້ແລ້ວ.',
    };
  }
  if (response.data.error.message === 'Customer already exist.') {
    return {
      ...data,
      message: 'ລະຫັດລູກຄ້າຖືກນຳໃຊ້ແລ້ວ.',
    };
  }
  if (response.data.error.message === 'No season.') {
    return {
      ...data,
      message: 'ກະລຸນາສ້າງລະດູການກ່ອນ',
    };
  }
  const { reason } = mappingDetailError(response.data.error.details, 0);
  if (!reason || typeof reason !== 'string') {
    return data;
  }
  switch (reason) {
    case 'SEASON_NOT_ACTIVE':
      return {
        ...data,
        message: 'ລະດູການບໍ່ພ້ອມໃຊ້ງານ,ກະລຸນາເພີ່ມລະດູການກ່ອນ',
      };
    case 'ALREADY_BINDING_RECEIPT':
      return {
        ...data,
        message: 'ໃບຮັບເງິນໄດ້ຜູກກັບໃບຮຽກເກັບເງິນແລ້ວ.',
      };
    case 'TX_SHOULD_NOT_PENDING':
      return {
        ...data,
        message: 'ລາຍການຮັບຊື້ບາງລາຍການຍັງບໍ່ຈ່າຍເງິນ',
      };
    case 'EXCEED_OUTSTANDING_AMOUNT':
      return {
        ...data,
        message: 'ຈໍານວນເງິນທີ່ຈ່າຍເກີນຍອດໃບຮຽກເກັບເງິນ',
      };
    case 'PASSWORD_MISMATCH':
      return {
        ...data,
        message: 'ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ',
      };
    case 'INVOICE_TOTAL_AMOUNT_ZERO':
      return {
        ...data,
        message: 'ໃບຮຽກເກັບເງິນມີມູນຄ່າເທົ່າ 0',
      };
    case 'INSUFFICIENT_FUNDS':
      return {
        ...data,
        message: 'ເງິນໃນກະເປົາຫນ້ອຍກວ່າຈໍານວນທີຊຳລະ',
      };
    default:
      return data;
  }
};
const mappingDetailError = (details: Array<any>, index: number) => {
  if (!Array.isArray(details)) {
    return 'ERROR';
  }
  if (details.length === 0) {
    return 'ERROR';
  }
  const value = details[index];
  if (!value) {
    return 'ERROR';
  }
  return value;
};
export const provinces = [
  {
    code: 'VI',
    label: 'ວຽງຈັນ',
    nameEn: 'Vientiane Province',
  },
  {
    code: 'XE',
    label: 'ເຊກອງ',
    nameEn: 'Sekong',
  },
  {
    code: 'VT',
    label: 'ນະຄອນຫຼວງວຽງຈັນ',
    nameEn: 'Vientiane Capital',
  },
  {
    code: 'LP',
    label: 'ຫຼວງພະບາງ',
    nameEn: 'Luang Prabang',
  },
  {
    code: 'CP',
    label: 'ຈຳປາສັກ',
    nameEn: 'Champasak',
  },
  {
    code: 'HO',
    label: 'ຫົວພັນ',
    nameEn: 'Houaphan',
  },
  {
    code: 'KM',
    label: 'ຄຳມ່ວນ',
    nameEn: 'Khammuan',
  },
  {
    code: 'OU',
    label: 'ອຸດົມໄຊ',
    nameEn: 'Oudomxay',
  },
  {
    code: 'PH',
    label: 'ຜົ້ງສາລີ',
    nameEn: 'Phongsali',
  },
  {
    code: 'SL',
    label: 'ສາລະວັນ',
    nameEn: 'Salavan',
  },
  {
    code: 'BK',
    label: 'ບໍ່ແກ້ວ',
    nameEn: 'Bokeo',
  },
  {
    code: 'BL',
    label: 'ບໍລິຄຳໄຊ',
    nameEn: 'Bolikhamxay',
  },
  {
    code: 'XI',
    label: 'ໄຊສົມບູນ',
    nameEn: 'Xaysomboun',
  },
  {
    code: 'SV',
    label: 'ສະຫວັນນະເຂດ',
    nameEn: 'Savannakhet',
  },
  {
    code: 'XS',
    label: 'ຊຽງຂວາງ',
    nameEn: 'Xiangkhoang',
  },
  {
    code: 'XA',
    label: 'ໄຊຍະບູລີ',
    nameEn: 'Xayyabuly',
  },
  {
    code: 'AT',
    label: 'ອັດຕະປື',
    nameEn: 'Attapue',
  },
  {
    code: 'LM',
    label: 'ຫຼວງນ້ຳທາ',
    nameEn: 'Luang Namtha',
  },
];

export const signSymbolCurrency = (currency: string) => {
  switch (currency.toLocaleUpperCase()) {
    case 'LAK':
      return '₭';
    case 'THB':
      return '฿';
    case 'USD':
      return '$';
    default:
      return '';
  }
};

//mapping gRPC error payloads
type ErrorStatus = string;
export const mappingErrorCode = (error: any): string | null => {
  if (!error || !error.response || !error.response.data) return null;
  if (
    typeof error.response.data !== 'object' ||
    typeof error.response.data.error !== 'object'
  ) {
    return 'error_response_unknown';
  }
  const { message, code, status, details } = error.response.data.error;
  if (!message || !code || !status) {
    return 'error_response_unknown';
  }
  return handlingErrorDetail(status, details);
};

const handlingErrorDetail = (
  status: ErrorStatus,
  details: any
): null | string => {
  if (!details || !Array.isArray(details)) {
    return 'error_response_unknown';
  }
  if (
    status === 'UNAUTHENTICATED' &&
    typeof details[0] === 'object' &&
    details[0].reason
  ) {
    return details[0].reason;
  }
  return null;
};

export const currencyOptions = [
  {
    value: 'LAK',
    label: 'LAK',
    symbol: '₭',
    name: 'ກີບ',
  },
  {
    value: 'THB',
    label: 'THB',
    symbol: '฿',
    name: 'ບາດ',
  },
  {
    value: 'USD',
    label: 'USD',
    symbol: '$',
    name: 'ໂດລາ',
  },
];
export const mappingCurrencySymbolOrName = (
  currency: string,
  type?: 'symbol' | 'name'
) => {
  const currencyOption = currencyOptions.find(
    (item) => item.value === currency
  );
  if (!currencyOption) return '';
  return currencyOption[type || 'symbol'];
};

export const productPricesStatus = [
  {
    value: 'APPROVE',
    label: 'ອະນຸມັດ',
  },
  {
    value: 'PENDING',
    label: 'ລໍຖ້າອະນຸມັດ',
  },
  {
    value: 'REJECT',
    label: 'ຖືກປະຕີເສດ',
  },
];

export const saleType = [
  {
    value: 'TIER_TO_TIER',
    label: 'ລານ-ລານ',
  },
  {
    value: 'EXPORT',
    label: 'ສົ່ງອອກຕ່າງປະເທດ',
  },
  {
    value: 'TIER_INFRONT',
    label: 'ຂາຍຕໍ່ຫນ້າລານ',
  },
];
export const mappingSaleType = (saleType: string, language?: 'la' | 'en') => {
  switch (`${saleType}&${language || 'la'}`) {
    case 'TIER_TO_TIER&la':
      return 'ລານ-ລານ';
    case 'EXPORT&la':
      return 'ສົ່ງອອກຕ່າງປະເທດ';
    case 'TIER_INFRONT&la':
      return 'ຂາຍຕໍ່ຫນ້າລານ';
    case 'TIER_TO_TIER&en':
      return 'Tier-to-Tier Sale';
    case 'EXPORT&en':
      return 'EXPORT';
    case 'TIER_INFRONT&en':
      return 'Tier Sale';
    default:
      return '';
  }
};
export const vihicleType = [
  {
    value: 'INSIDE',
    label: 'ລົດບໍລິສັດ',
  },
  {
    value: 'OUTSIDE',
    label: 'ລົດເຊົ່າ',
  },
];
export const salePaymentMethods = [
  {
    value: 'CASH',
    label: 'ເງິນສົດ',
  },
  {
    value: 'IBANK',
    label: 'ເງິນໂອນ',
  },
  {
    value: 'NO_PAYMENT',
    label: 'ບໍ່ຈ່າຍຈິງ',
  },
];
export const mappingSalePaymentMethods = (type: string) => {
  switch (type) {
    case 'CASH':
      return 'ເງິນສົດ';
    case 'IBANK':
      return 'ເງິນໂອນ';
    case 'NO_PAYMENT':
      return 'ບໍ່ຈ່າຍຈິງ';
    default:
      return '';
  }
};
export const resourceKey = {
  users: 'users',
  tierCustomers: 'customers',
  tierProducts: 'products',
  tierWalletAccounts: 'wallets',
  tierWalletTransactions: 'transfers',
  tierUserTiers: 'tiers',
  tierPurchasePrices: 'purchasePrices',
  tierPurchaseQualitycheck: 'purchases',
  tierSalePurchaseOrders: 'sales',
  tierInventoryStocks: 'inventories',
  adminProducts: 'products',
  adminPredict: 'admin-predict',
  adminReportPurchases: 'admin-report-purchase',
  adminReportSales: 'admin-report-sale',
  adminReportInventories: 'admin-report-inventory',
  adminPurchasePrices: 'admin-purchasePrices',
  adminUsers: 'admin-users',
  adminTiers: 'admin-tiers',
  adminWallets: 'admin-wallets',
  adminCustomers: 'admin-customers',
  adminExchangeRates: 'exchangeRates',
  adminRoles: 'admin-roles',
  adminSeasons: 'seasons',
  adminZones: 'admin-zones',
  adminCompanies: 'companies',
  zonePurchasePrices: 'zone-purchasePrices',
  rivalPurchasePrices: 'rivalPurchasePrices',
  expenseTypes: 'expenseTypes', //Super-admin
  expenses: 'expenses', //Tier
  activities: 'activities',
  sync: 'sync', //Tier
} as const;
export const permissionKey = {
  read: 'read',
  create: 'create',
  update: 'update',
  approve: 'approve',
  reject: 'reject',
  sync: 'sync',
} as const;
export const resourceAccessSuperAdmin = {
  [resourceKey.adminCompanies]: {
    [permissionKey.read]: false,
    [permissionKey.update]: false,
  },
  [resourceKey.adminPredict]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
  },
  [resourceKey.adminPurchasePrices]: {
    [permissionKey.read]: false,
    [permissionKey.approve]: false,
    [permissionKey.reject]: false,
    [permissionKey.create]: false,
  },
  [resourceKey.activities]: {
    [permissionKey.read]: false,
  },
  [resourceKey.adminZones]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
    [permissionKey.update]: false,
  },
  [resourceKey.adminExchangeRates]: {
    [permissionKey.read]: false,
    [permissionKey.update]: false,
  },
  [resourceKey.adminUsers]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
    [permissionKey.update]: false,
  },
  [resourceKey.adminCustomers]: {
    [permissionKey.read]: false,
    // [permissionKey.create]: false,
    // [permissionKey.update]: false,
  },
  [resourceKey.adminTiers]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
    // [permissionKey.update]: false,
  },
  [resourceKey.adminWallets]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
    // [permissionKey.update]: false,
  },
  [resourceKey.adminSeasons]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
    [permissionKey.update]: false,
  },
  [resourceKey.adminProducts]: {
    [permissionKey.read]: false,
  },
  [resourceKey.adminRoles]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
    [permissionKey.update]: false,
  },
  [resourceKey.expenseTypes]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
    [permissionKey.update]: false,
  },
  [resourceKey.adminReportPurchases]: {
    [permissionKey.read]: false,
  },
  [resourceKey.adminReportSales]: {
    [permissionKey.read]: false,
  },
  [resourceKey.adminReportInventories]: {
    [permissionKey.read]: false,
  },
};
export const resourceAccessTier = {
  // [resourceKey.users]: {
  //   [permissionKey.read]: false,
  //   [permissionKey.create]: false,
  //   [permissionKey.update]: false,
  // },
  // [resourceKey.tierProducts]: {
  //   [permissionKey.read]: false,
  // },
  [resourceKey.tierPurchasePrices]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
  },
  [resourceKey.tierWalletAccounts]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
  },
  [resourceKey.tierWalletTransactions]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
  },
  [resourceKey.tierCustomers]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
    [permissionKey.update]: false,
  },
  [resourceKey.tierPurchaseQualitycheck]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
    // [permissionKey.update]: false,
  },
  [resourceKey.tierInventoryStocks]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
    // [permissionKey.update]: false,
  },
  [resourceKey.tierSalePurchaseOrders]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
    [permissionKey.sync]: false,
  },
  [resourceKey.rivalPurchasePrices]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
    [permissionKey.update]: false,
  },
  [resourceKey.expenses]: {
    [permissionKey.read]: false,
    [permissionKey.create]: false,
    [permissionKey.update]: false,
  },
};
export const resourceAccessZone = {
  [resourceKey.zonePurchasePrices]: {
    [permissionKey.read]: false,
    [permissionKey.approve]: false,
    [permissionKey.reject]: false,
  },
};
export const resourceAccess = {
  ...resourceAccessSuperAdmin,
  ...resourceAccessTier,
  ...resourceAccessZone,
};
export type ResourceAccess = typeof resourceAccess;
export type ResourceKey = keyof ResourceAccess;
export type PermissionKey = keyof ResourceAccess[ResourceKey];

export const mappingResourceName = (resourceKey: ResourceKey | string) => {
  switch (resourceKey) {
    case 'companies':
      return 'ຂໍ້ມູນບໍລິສັດ';
    case 'admin-customers':
      return 'ຂໍ້ມູນລູກຄ້າ';
    case 'exchangeRates':
      return 'ອັດຕາແລກປ່ຽນ';
    case 'admin-purchasePrices':
      return 'ລາຄາສິນຄ້າປະຈຳວັນ ';
    case 'products':
      return 'ຂໍ້ມູນສິນຄ້າ';
    case 'seasons':
      return 'ຂໍ້ມູນລະດູການ';
    case 'admin-tiers':
      return 'ຂໍ້ມູນລານ';
    case 'admin-users':
      return 'ຂໍ້ມູນຜູ້ໃຊ້';
    case 'admin-wallets':
      return 'ຂໍ້ມູນກະເປົາເງິນ';
    case 'admin-roles':
      return 'ຂໍ້ມູນສິດ ແລະ ການເຂົ້າເຖິງ';
    case 'zone-purchasePrices':
      return 'ລາຄາສິນຄ້າປະຈຳວັນ';
    case 'customers':
      return 'ຂໍ້ມູນລູກຄ້າ';
    // case 'products':
    //   return 'ຂໍ້ມູນສິນຄ້າ';
    case 'purchasePrices':
      return 'ລາຄາສິນຄ້າ';
    case 'wallets':
      return 'ຂໍ້ມູນກະເປົາເງິນ';
    case 'transfers':
      return 'ຂໍ້ມູນທຸກລະກຳກະເປົາເງິນ';
    // case 'users':
    //   return 'ຂໍ້ມູນຜູ້ໃຊ້';
    case 'inventories':
      return 'ຈັດການການແປຮູບສິນຄ້າ';
    case 'purchases':
      return 'ຈັດການຮັບຊື້ສິນຄ້າ';
    case 'sales':
      return 'ຈັດການການຂາຍ';
    case 'admin-zones':
      return 'ຈັດການການໂຊນ';
    case 'rivalPurchasePrices':
      return 'ຈັດການການລາຄາລານຄູ່ແຂ່ງ';
    case 'expenseTypes':
      return 'ຈັດການປະເພດລາຍຈ່າຍ';
    case 'expenses':
      return 'ຈັດການລາຍຈ່າຍ';
    case 'activities':
      return 'ບັນທຶກກິດຈະກຳຜູ້ໃຊ້';
    case 'admin-report-purchase':
      return 'ລາຍງານກ່ຽວກັບການຊື້';
    case 'admin-report-sale':
      return 'ລາຍງານກ່ຽວກັບການຂາຍ';
    case 'admin-report-inventory':
      return 'ລາຍງານກ່ຽວກັບການແປຮູບສິນຄ້າ';
    case 'admin-predict':
      return 'ຕັ້ງຄ່າຄວາມຊຸ່ມ';
    default:
      return '';
  }
};
export const mappingPermissionName = (
  permissionKey: string | PermissionKey,
  resourceKey: ResourceKey | string
) => {
  if (permissionKey === 'create' && resourceKey === 'inventories') {
    return 'ເພີ່ມຂໍ້ມູນກ່ຽວການແປຮູບສິນຄ້າ';
  }
  if (permissionKey === 'create' && resourceKey === 'sales') {
    return 'ເພີ່ມຂໍ້ມູນກ່ຽວກັບການຂາຍ';
  }
  if (permissionKey === 'create' && resourceKey === 'purchases') {
    return 'ເພີ່ມຂໍ້ມູນກ່ຽວກັບການສັ່ງຊື້';
  }
  if (permissionKey === 'create' && resourceKey === 'wallets') {
    return 'ການເຕີມເງິນ';
  }
  if (permissionKey === 'create' && resourceKey === 'transfers') {
    return 'ການໂອນເງິນ';
  }
  if (permissionKey === 'sync' && resourceKey === 'sales') {
    return 'ເພີ່ມຂໍ້ມູນການຂາຍຈາກຊິງ';
  }
  if (permissionKey === 'create') {
    return 'ສ້າງ';
  }
  if (permissionKey === 'update') {
    return 'ແກ້ໄຂ';
  }
  if (permissionKey === 'approve') {
    return 'ອະນຸມັດລາຄາສິນຄ້າ';
  }
  if (permissionKey === 'reject') {
    return 'ປະຕິເສດລາຄາສິນຄ້າ';
  }
  return 'ເບິ່ງຂໍ້ມູນ';
};
export const mappingAllowAccessResources = (
  resources: Resource<ResourceKey>[]
) => {
  let allowed: ResourceAccess = JSON.parse(JSON.stringify(resourceAccess));
  resources.forEach((resource) => {
    if (allowed[resource.name]) {
      const permissionKeys = Object.keys(
        allowed[resource.name]
      ) as PermissionKey[];
      permissionKeys.forEach((permissionKey) => {
        allowed[resource.name][permissionKey] = mappingPermission(
          permissionKey,
          resource.permissions
        );
      });
    }
  });
  return allowed;
};
const mappingPermission = (
  permissionName: PermissionKey,
  permissions: Permission[]
) => {
  const permission = permissions.find((item) => item.name === permissionName);
  return permission ? true : false;
};
const navMode = ['local', 'development', 'production'];
const mappingNavigationItems = (
  allowed: ResourceAccess,
  items: NavigationItem[]
) => {
  const navigationItems = (
    navigationsData: NavigationItem[],
    item: NavigationItem
  ): NavigationItem[] => {
    const mode: number = navMode.findIndex(
      (value) => value === (process.env.REACT_APP_SIDEBAR_MENU || 'development')
    );
    if (item.children) {
      const children = [...(item.children || [])].reduce(navigationItems, []);
      if (children.length > 0) {
        return [...navigationsData, { ...item, children: children }];
      }
      return navigationsData;
    }
    if (
      item.resource &&
      item.resource === '*' &&
      !item.children &&
      item.mode >= mode
    ) {
      return [...navigationsData, item];
    }
    const allowedItem =
      allowed[item.resource as ResourceKey] &&
      allowed[item.resource as ResourceKey]['read'];

    if (item.to && !item.children && allowedItem && item.mode >= mode) {
      return [...navigationsData, item];
    }
    return navigationsData;
  };
  return items.reduce(navigationItems, []);
};
export const allowedNavigations = (allowed: ResourceAccess) => {
  const cloneNavigations = JSON.parse(JSON.stringify(navigations));
  const navTitlekeys: Array<NavigationKey> = ['superAdmin', 'admin', 'tier'];
  return navTitlekeys.reduce((newNavigations, key) => {
    const navigationItems = cloneNavigations[key];
    if (navigationItems && navigationItems.items) {
      const allowedItems = mappingNavigationItems(
        allowed,
        navigationItems.items
      );
      if (allowedItems.length !== 0) {
        navigationItems.items = allowedItems;
        return { ...newNavigations, [key]: navigationItems };
      }

      return newNavigations;
    }
    return newNavigations;
  }, {} as Navigation);
};

export const mappingRoleToCreate = (role: CreateRoleForm) => {
  const { resources } = role;
  return Object.keys(resources).reduce((newResources, key) => {
    const permissions = resources[key as ResourceKey];
    const newPermissions = Object.keys(permissions).reduce(
      (permissionData, action) => {
        if (permissions[action as PermissionKey]) {
          return [
            ...permissionData,
            {
              name: action,
              title: action,
            },
          ];
        }
        return permissionData;
      },
      [] as PermissionCreate[]
    );
    if (newPermissions.length !== 0) {
      return [
        ...newResources,
        {
          name: key as ResourceKey,
          title: key as ResourceKey,
          permissions: newPermissions,
        },
      ];
    }
    return newResources;
  }, [] as ResourcesCreate[]);
};

export const base64Decode = (str: string) => {
  try {
    if (typeof str !== 'string') return {};
    const raw = decodeURIComponent(window.atob(str));
    const data = JSON.parse(raw);
    if (typeof data !== 'object') return {};
    return data;
  } catch (error) {
    return {};
  }
};

export const mappingAcceptRoutes = (
  resources: ResourceAccess,
  routes: Router[]
): Router[] => {
  const cloneRoutes = routes.reduce((newRoutes, route) => {
    if (!route.resource) return newRoutes;
    if (route.resource && route.resource === 'public')
      return [...newRoutes, route];
    const resource = resources[route.resource];

    if (
      resource &&
      route.permission &&
      resource[route.permission as PermissionKey]
    )
      return [...newRoutes, route];
    return newRoutes;
  }, [] as Router[]);
  return cloneRoutes;
};

type FieldValues = Record<string, any>;

type GetAllDataWithCursorNextPageProps<
  R extends FieldValues = FieldValues,
  K extends string = string
> = {
  keyResponse: K;
  query?: string;
  httpRequest: (queries: string) => Promise<
    AxiosResponse<
      {
        [key in K]: R;
      } & {
        nextPageToken: string;
      }
    >
  >;
  maxDataLength?: number;
  pageSize?: number;
};
export const getAllDataWithCursorNextPage = async <
  R extends FieldValues = FieldValues,
  K extends string = string
>({
  keyResponse,
  httpRequest,
  pageSize = 250,
  maxDataLength = 2000,
}: GetAllDataWithCursorNextPageProps<R, K>): Promise<
  R[] | AxiosError | undefined
> => {
  let values: R[] = [];
  let nextPageToken = '';
  let dataLength = 0;
  do {
    try {
      let queries = `?pageSize=${pageSize}`;
      if (nextPageToken.length !== 0) {
        queries = queries + `&pageToken=${nextPageToken}`;
        nextPageToken = '';
      }
      if (maxDataLength === dataLength) {
        break;
      }
      const { data } = await httpRequest(queries);
      dataLength = dataLength + pageSize;
      const pageToken = data['nextPageToken'];
      if (!data[keyResponse]) {
        throw new Error('Key data response not found.');
      }
      values = values.concat(data[keyResponse]);
      if (pageToken && pageToken.length !== 0) {
        nextPageToken = pageToken;
      }
    } catch (error: any) {
      return error;
    }
  } while (nextPageToken.length !== 0);
  return values;
};

export const joinQueryParamsToString = <T extends FieldValues = FieldValues>(
  values: T,
  keysParams?: (keyof T)[]
): string => {
  let query = '';
  if (typeof values !== 'object') return '';
  const keys = keysParams || Object.keys(values);
  keys.forEach((key) => {
    const value = values[key];
    const typeOfValue = typeof value;
    if (
      (typeOfValue === 'boolean' ||
        typeOfValue === 'number' ||
        typeOfValue === 'string') &&
      value &&
      typeof key === 'string'
    ) {
      query = query + `&${key}=${encodeURIComponent(value)}`;
    }
  });
  return query;
};

export const queryParamsToObject = <V extends FieldValues = FieldValues>(
  queries: V,
  keysParams?: (keyof V)[]
): OptionalOne<V, keyof V> => {
  const objectKeys = Object.keys(queries);
  return (keysParams || objectKeys).reduce((values, key) => {
    if (key === 'orderBy' && !queries['orderBy']) {
      (values['orderBy'] as string) = 'ASC';
      return values;
    }
    const value = queries[key];
    const isBoolean = value && (value === 'true' || value === 'false');
    if (value && !isBoolean) {
      values[key] = value;
    }
    if (isBoolean) {
      values[key] = JSON.parse(value);
    }
    //set default order

    return values;
  }, {} as OptionalOne<V, keyof V>);
};
export const joinParamsToStringHttpRequest = <
  TFormValues extends FieldValues = FieldValues
>({
  values,
  keysParams,
}: {
  values: TFormValues;
  keysParams?: (keyof TFormValues)[];
}): string => {
  let queryString = '';
  if (typeof values !== 'object') return '';
  const keys = keysParams || Object.keys(values);
  keys.forEach((key) => {
    const value = values[key];
    const typeOfValue = typeof value;
    if (
      (typeOfValue === 'boolean' || typeOfValue === 'number') &&
      typeof key === 'string'
    ) {
      return (queryString = queryString + `&${key}=${value}`);
    }
    if (
      typeOfValue === 'string' &&
      typeof key === 'string' &&
      value !== 'all'
    ) {
      return (queryString =
        queryString + `&${key}=${encodeURIComponent(value)}`);
    }
  });
  return queryString.slice(1);
};
type MappingParamsToPayloadRetern<T> = {
  [key in keyof T]?: T[keyof T];
};
export const mappingParamsToPayload = <T extends FieldValues = FieldValues>({
  values,
  keysParams,
}: {
  values: T;
  keysParams?: (keyof T)[];
}): MappingParamsToPayloadRetern<T> => {
  if (typeof values !== 'object') return {};
  let queries: MappingParamsToPayloadRetern<T> = {};
  const keys = keysParams || Object.keys(values);
  // const par = JSON.parse(JSON.stringify(values).replace('true', true));
  keys.forEach((key: keyof T) => {
    const value = values[key];
    const typeOfValue = typeof value;
    if (typeOfValue === 'boolean' || typeOfValue === 'number') {
      queries[key] = value;
    }
    const isBoolean = value && (value === 'true' || value === 'false');
    if (typeOfValue === 'string' && !isBoolean && value !== 'all') {
      queries[key] = value;
    }
    if (typeOfValue === 'string' && isBoolean) {
      queries[key] = JSON.parse(value);
    }
  });
  return queries;
};

export const compareSearchQueriesAndValues = (
  queryParams: any,
  values: any,
  valueIsEmptry?: boolean
): boolean => {
  const searchQueriesString = JSON.stringify(queryParams || {});
  const cloneValues = Object.keys(values || {}).reduce((accumulator, key) => {
    if (values[key] != null) {
      accumulator[key] = values[key];
    }
    return accumulator;
  }, {} as any);
  const valuesString = JSON.stringify(cloneValues || {});
  const isCompare = searchQueriesString === valuesString;
  const isEmptry = searchQueriesString !== '{}' && valuesString !== '{}';
  if (valueIsEmptry && searchQueriesString === '{}' && valuesString === '{}') {
    return true;
  }
  return isCompare || isEmptry;
};
// Define arrays for the number words
const ones = [
  '',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
];
const tens = [
  '',
  '',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
];
const teens = [
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
];
const scales = ['', 'thousand', 'million'];

export function convertNumberToWords(wordString: string): string {
  let number = Number.parseFloat(wordString) || 0;
  if (number === 0) {
    return 'zero';
  }
  let words = '';
  let hasThousands = false;
  for (let i = 0; number > 0; i++) {
    const hundreds = number % 1000;
    const digit1 = Math.floor(hundreds / 100);
    const digit2 = Math.floor((hundreds % 100) / 10);
    const digit3 = hundreds % 10;

    let hundredsWords = '';
    if (digit1 > 0) {
      hundredsWords = ones[digit1] + ' hundred';
    }

    let tensWords = '';
    if (digit2 === 1) {
      tensWords = teens[digit3];
    } else {
      tensWords = tens[digit2];
      if (digit3 > 0) {
        tensWords += '-' + ones[digit3];
      }
    }

    let scaleWords = '';
    if (hundreds > 0 && i > 0) {
      scaleWords = scales[i];
    }

    let andConjunction = '';
    if (hundreds > 0 && hasThousands && number > 999) {
      andConjunction = 'and';
    }

    if (hundreds > 0) {
      words = `${hundredsWords} ${tensWords} ${scaleWords} ${andConjunction} ${words}`;
      hasThousands = true;
    }

    number = Math.floor(number / 1000);
  }
  const getHyphen = words.trim().charAt(0);
  let letter = words.trim();
  if (getHyphen === '-') {
    letter = words.trim().replace('-', '');
  }
  return letter.charAt(0).toUpperCase() + letter.slice(1);
}

interface CurrentPage {
  page: number;
  pageSize: number;
  pageTokens: string[];
}
interface Payload {
  page?: number;
  pageSize?: number;
  nextPageToken: string;
}
interface SetNewPageInfo {
  page?: number;
  pageSize?: number;
  pageTokens: string[];
}
export const setPageInfo = (
  currentPage: CurrentPage,
  payload: Payload
): SetNewPageInfo => {
  let pageInfo: SetNewPageInfo = {
    pageTokens: [...currentPage.pageTokens],
  };
  const pageToken = currentPage.pageTokens.find(
    (token) => token === payload.nextPageToken
  );
  const pageSizeIsChange =
    payload.pageSize && payload.pageSize !== pageInfo.pageSize;
  const setPageTokensIsEmpty = pageSizeIsChange || payload.page === 1;

  if (payload.page) {
    pageInfo.page = payload.page;
  }
  if (pageSizeIsChange) {
    pageInfo.pageSize = payload.pageSize;
    pageInfo.page = 1;
  }
  if (setPageTokensIsEmpty) {
    pageInfo.pageTokens = [];
  }
  if (
    (setPageTokensIsEmpty || !pageToken) &&
    payload.nextPageToken.length !== 0
  ) {
    pageInfo.pageTokens.push(payload.nextPageToken);
  }
  return pageInfo;
};

export const fileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const prevOrNextCursorPage = ({
  page,
  pageTokens,
  nextOrPrev,
}: {
  pageTokens: string[];
  page: number;
  nextOrPrev: 'prev' | 'next';
}) => {
  const pageInfoDefault = 1;
  let currentPage = nextOrPrev === 'next' ? page + 1 : page - 1;
  let indexPageToken =
    nextOrPrev === 'next' ? page - 1 : page - 2 - pageInfoDefault;
  if (page === pageInfoDefault) {
    indexPageToken = 0;
  }
  let pageToken = pageTokens[indexPageToken];

  if (!pageToken) {
    return {
      page: currentPage,
    };
  }
  return {
    page: currentPage,
    pageToken: pageToken,
  };
};

export const provinceName = (value: string) => {
  const province = provinces.find(
    (p) => p.nameEn.toLocaleLowerCase().replace(' ', '-') === value
  );
  if (province) return province.label;
  return value;
};

export const showFormatter = (value: number, keyName?: string | null) => {
  switch (keyName || 'default') {
    case 'amount':
      return `${numberFormat(value)} LAK`;
    case 'weight':
      return `${numberFormat(value)} KG`;
    default:
      return `${numberFormat(value)}`;
  }
};
export const lotStatus = (status: LotStatus): string => {
  switch (status) {
    case 'READY':
      return 'ພ້ອມຂາຍ';
    case 'SOLDOUT':
      return 'ຂາຍແລ້ວ';
    default:
      return '';
  }
};
