import { createReducer, createAction } from '@reduxjs/toolkit';
import { Product } from 'models';
export interface InitialState {
  productsList: {
    products: Product[];
    isLoading: boolean;
    error: string | null;
  };
}
const initialState: InitialState = {
  productsList: {
    error: null,
    isLoading: false,
    products: [],
  },
};
export enum Types {
  cancelRequestAPI = 'super-admin/products@cancelRequestAPI',
  getProductsRequest = 'super-admin/products@getProductsRequest',
  getProductsSuccess = 'super-admin/products@getProductsSuccess',
  getProductsFailure = 'super-admin/products@getProductsFailure',
}
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getProductsRequest = createAction(Types.getProductsRequest);
export const getProductsSuccess = createAction<Product[]>(
  Types.getProductsSuccess
);
export const getProductsFailure = createAction<string>(
  Types.getProductsFailure
);

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(cancelRequestAPI, (state) => {
      return { ...state, isLoading: false, isCreating: false };
    })
    .addCase(getProductsRequest, (state) => {
      state.productsList.isLoading = true;
      state.productsList.error = null;
    })
    .addCase(getProductsSuccess, (state, { payload }) => {
      return {
        ...state,
        productsList: {
          ...state.productsList,
          products: payload,
          isLoading: false,
        },
      };
    })
    .addCase(getProductsFailure, (state, { payload }) => {
      state.productsList.isLoading = false;
      state.productsList.error = payload;
    });
});

export default reducer;
