import { createReducer, createAction } from '@reduxjs/toolkit';
import { Tier, Profile, Signature } from 'models';
import { ResourceAccess, resourceAccess } from 'utils';
import { Navigation } from 'config/navigations';

type dialogId = null | 'updatePassword' | 'updateProfile';
export type IsAuthenticated = null | 'PENDING' | 'SUCCESS' | 'FAILURE';
export type InitialState = {
  isAuthenticated: IsAuthenticated;
  login: {
    isLoading: boolean;
    error: null | string;
    loginIsSuccess: boolean;
  };
  accept: {
    isLoading: boolean;
    error: null | string;
    profile: null | Profile;
    tiers: Tier[];
    tier: null | Tier;
    resourceAccess: ResourceAccess;
    navigations: Navigation;
    isAfterUpdate: boolean;
    isUpdating: boolean;
  };
  dialogId: dialogId;
};

const initialState: InitialState = {
  isAuthenticated: null,
  login: {
    isLoading: false,
    error: null,
    loginIsSuccess: false,
  },
  accept: {
    error: null,
    isLoading: false,
    profile: null,
    tiers: [],
    tier: null,
    resourceAccess: resourceAccess,
    navigations: {
      admin: {
        items: [],
        title: '',
      },
      superAdmin: {
        items: [],
        title: '',
      },
      tier: {
        items: [],
        title: '',
      },
    },
    isAfterUpdate: false,
    isUpdating: false,
  },
  dialogId: null,
};

export const enum Types {
  cancelRequestAPI = 'auth@cancelRequestAPI',
  loginRequest = 'auth@loginRequest',
  loginSuccess = 'auth@loginSuccess',
  afterLoginSuccess = 'auth@afterLoginSuccess',
  loginFailure = 'auth@loginFailure',
  authorizeRequest = 'authorizeRequest',
  authorizeSuccess = 'authorizeSuccess',
  authorizeFailure = 'authorizeFailure',
  setProfile = 'auth@setProfile',
  setTiers = 'auth@setTiers',
  setTier = 'auth@setTier',
  setTiersFailure = 'auth@setTiersFailure',
  setPermissions = 'auth@setPermissions',
  addTierToUser = 'auth@addTierToUser',
  logoutRequest = 'auth@logoutRequest',
  setIsAfterUpdate = 'auth@setIsAfterUpdate',
  onChangeDialogId = 'auth@onChangeDialogId',
}
type TierName = string | undefined;
type Error = string | null;
export type SetPermissions = {
  resourceAccess: ResourceAccess;
  navigations: Navigation;
};
export const loginRequest = createAction<Signature>(Types.loginRequest);
export const afterLoginSuccess = createAction(Types.afterLoginSuccess);
export const loginSuccess = createAction(Types.loginSuccess);
export const loginFailure = createAction<Error>(Types.loginFailure);
export const setProfile = createAction<Profile, Types.setProfile>(
  Types.setProfile
);
export const setPermissions = createAction<
  SetPermissions,
  Types.setPermissions
>(Types.setPermissions);
export const setTiers = createAction<Tier[], Types.setTiers>(Types.setTiers);
export const setTier = createAction<Tier | null, Types.setTier>(Types.setTier);
export const authorizeRequest = createAction<TierName>(Types.authorizeRequest);
export const authorizeSuccess = createAction(Types.authorizeSuccess);
export const authorizeFailure = createAction<Error, Types.authorizeFailure>(
  Types.authorizeFailure
);
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const addTierToUser = createAction<Tier, Types.addTierToUser>(
  Types.addTierToUser
);
export const logoutRequest = createAction(Types.logoutRequest);
export const setIsAfterUpdate = createAction<boolean, Types.setIsAfterUpdate>(
  Types.setIsAfterUpdate
);
export const onChangeDialogId = createAction<dialogId>(Types.onChangeDialogId);

// export const actions = { loginRequest };
const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    return { ...state, isAuthenticated: null };
  });
  builder.addCase(loginRequest, (state) => {
    state.login.isLoading = true;
    state.login.error = null;
  });
  builder.addCase(loginFailure, (state, { payload }) => {
    state.login.isLoading = false;
    state.login.error = payload;
  });
  builder.addCase(loginSuccess, (state) => {
    state.login.isLoading = false;
    state.login.loginIsSuccess = true;
  });
  builder.addCase(authorizeRequest, (state) => {
    state.isAuthenticated = 'PENDING';
    state.accept.isLoading = true;
    state.accept.error = null;
  });
  builder.addCase(authorizeSuccess, (state) => {
    state.isAuthenticated = 'SUCCESS';
    state.accept.isLoading = false;
  });
  builder.addCase(authorizeFailure, (state, { payload }) => {
    state.isAuthenticated = 'FAILURE';
    state.accept.isLoading = false;
    state.accept.error = payload;
  });
  builder.addCase(setProfile, (state, { payload }) => {
    state.accept.profile = payload;
  });
  builder.addCase(setTiers, (state, { payload }) => {
    state.accept.tiers = payload;
  });
  builder.addCase(setTier, (state, { payload }) => {
    state.accept.tier = payload;
  });
  builder.addCase(setPermissions, (state, { payload }) => {
    state.accept.resourceAccess = payload.resourceAccess;
    state.accept.navigations = payload.navigations;
  });
  builder.addCase(afterLoginSuccess, (state) => {
    state.login.loginIsSuccess = false;
  });
  builder.addCase(addTierToUser, (state, { payload }) => {
    state.accept.tiers.push(payload);
  });

  builder.addCase(setIsAfterUpdate, (state, { payload }) => {
    state.accept.isAfterUpdate = payload;
  });
  builder.addCase(onChangeDialogId, (state, { payload }) => {
    state.dialogId = payload;
  });
});

export default reducer;
