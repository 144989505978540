import {
  all,
  fork,
  put,
  call,
  takeLatest,
  select,
  take,
  takeLeading,
} from 'redux-saga/effects';
import moment from 'moment';
import { saveAs } from 'file-saver';

import { addAlert } from 'store/notify';
import API, { Canceler, createCancelToken } from 'services/defaultInstance';
import * as actions from './index';
import { GetStocksResponse } from 'models';
import type { DataSource } from 'models/lots';
import { findErrorToData, mappingParamsToPayload } from 'utils';
import { RootState } from 'store/index';

let cancels: Canceler[] = [];
function* getLots({ payload }: ReturnType<typeof actions.getLotsRequest>) {
  const {
    inventoryLots: { lotsList },
    auth: {
      accept: { tier: currentTier },
    },
  }: RootState = yield select((state) => state);
  const { pageSize, page, tier, ...payloadParams } = payload;
  const params: DataSource.LotsQueriesAPI = {
    pageSize: pageSize || lotsList.pageSize,
    tier: tier || currentTier?.name || '',
    ...payloadParams,
  };

  try {
    const { data }: DataSource.GetLotsResponse = yield call(
      API.get,
      `/v1/inventory/lots`,
      {
        params,
      }
    );
    let entries: actions.GetLotsSuccess = {
      data: data.lots,
      pageSize: pageSize || lotsList.pageSize,
      page: page || lotsList.page,
    };
    const nextPageTokenLength = data.nextPageToken.length;
    if (pageSize || page === 1) {
      entries.pageTokens =
        nextPageTokenLength !== 0
          ? {
              1: data.nextPageToken,
            }
          : {};
    }
    const pageTokensLength = Object.values(lotsList.pageTokens).length;
    if (page && page > pageTokensLength && nextPageTokenLength !== 0) {
      entries.pageTokens = {
        ...lotsList.pageTokens,
        [page]: data.nextPageToken,
      };
    }
    yield put(actions.getLotsSuccess(entries));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getLotsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* createLot({ payload }: ReturnType<typeof actions.createLotRequest>) {
  const {
    auth,
    data: { products },
  }: RootState = yield select((state) => state);
  const cancelToken: Canceler = yield createCancelToken();
  cancels.push(cancelToken);
  const product = products.entities.find((p) => p.name === payload.productName);
  const data: DataSource.CreateLotAPI = {
    description: payload.description || '',
    tier: auth.accept.tier?.name || '',
    number: payload.number,
    endAt: moment(payload.endAt).format(),
    startAt: moment(payload.startAt).format(),
    product: {
      name: product?.name || '',
      title: product?.title || '',
    },
  };
  try {
    yield call(API.post, `v1/inventory/lots`, data);
    yield put(actions.createLotSuccess());
    yield put(
      actions.getLotsRequest({
        page: 1,
      })
    );
    yield put(
      addAlert({
        message: 'ສ້າງລ໋ອດສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    cancels = [];
  } catch (error: any) {
    cancels = [];
    const errorData = findErrorToData({ error: error });
    yield put(actions.createLotFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getLot(lotId: string) {
  try {
    const { data }: DataSource.GetLotResponse = yield call(
      API.get,
      `/v1/inventory/lots/${lotId}`
    );
    yield put(actions.getLotSuccess(data.lot));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getLotFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getStocks(lotId: string) {
  try {
    const { data }: GetStocksResponse = yield call(
      API.get,
      `/v1/inventory/stocks?lotId=${lotId}&pageSize=250`
    );
    yield put(actions.getStocksSuccess(data.stocks));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getStocksFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* closeLot() {
  const {
    inventoryLots: {
      lot: { data },
    },
  }: RootState = yield select((state) => state);
  try {
    yield call(API.post, `/v1/inventory/lots-summary`, {
      lotId: data?.id || '',
    });
    yield put(actions.closeLotSuccess());
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.closeLotFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* exportCsv({ payload }: ReturnType<typeof actions.exportCsvRequest>) {
  const {
    auth: { accept },
  }: RootState = yield select((state) => state);
  try {
    const queries: string = yield mappingParamsToPayload({
      values: {
        ...payload,
        tier: accept.tier?.name || '',
      },
      keysParams: ['tier', 'status'],
    });
    const { data } = yield call(
      API.post,
      `/v1/inventory/lots-exportCsv`,
      queries
    );
    const blob = new Blob(['\uFEFF' + data], {
      type: 'text/csv; charset=utf-8',
    });
    yield saveAs(blob, `ລາຍການລ໋ອດ${moment().format('DD/MM/YYYY')}.csv`);
    yield put(actions.exportCsvSuccess());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.exportCsvFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type GetLotRequest = ReturnType<typeof actions.getLotRequest>;
function* getLotAndStocks({ payload }: GetLotRequest) {
  yield fork(getLot, payload);
  yield take(actions.Types.getLotSuccess);
  yield fork(getStocks, payload);
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetLots() {
  yield takeLatest(actions.Types.getLotsRequest, getLots);
}
function* watchCreateLot() {
  yield takeLeading(actions.Types.createLotRequest, createLot);
}
function* watchGetLotAndStocks() {
  yield takeLatest(actions.Types.getLotRequest, getLotAndStocks);
}
function* watchCloseLot() {
  yield takeLeading(actions.Types.closeLotRequest, closeLot);
}
function* watchExportCsv() {
  yield takeLatest(actions.Types.exportCsvRequest, exportCsv);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetLots),
    fork(watchCreateLot),
    fork(watchGetLotAndStocks),
    fork(watchCloseLot),
    fork(watchExportCsv),
  ]);
}
export default saga;
