import {
  all,
  fork,
  takeLatest,
  put,
  call,
  take,
  select,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';
import API from 'services/defaultInstance';
import * as auth from './index';
import { addAlert } from 'store/notify';
import {
  findErrorToData,
  mappingAllowAccessResources,
  allowedNavigations,
} from 'utils';
import { Navigation } from 'config/navigations';
import {
  GetTiersResponse,
  GetProfileResponse,
  LoginResponse,
  GetResourcesResponse,
} from 'models';
import { RootState } from 'store';
const { Types, ...actions } = auth;

const loginAPI = (body: any) => API.post(`/v1/auth/login`, body);
const getProfileAPI = () => API.get(`/v1/auth/me`);
const getTiersAPI = (username: string) =>
  API.get(`/v1/users/${username}/tiers`);
const getPermissionsAPI = (roleName: string) =>
  API.get(`/v1/roles/${roleName}/resources`);

function* login({ payload }: ReturnType<typeof actions.loginRequest>) {
  try {
    const { data }: LoginResponse = yield call(loginAPI, payload);
    localStorage.setItem('accessToken', data.accessToken);
    localStorage.setItem('refreshToken', data.refreshToken);
    yield put(actions.loginSuccess());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    if (errorData) {
      yield put(actions.loginFailure(errorData.message));
    }
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getProfile() {
  try {
    const { data }: GetProfileResponse = yield call(getProfileAPI);
    yield put(actions.setProfile(data.user));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    if (errorData) {
      yield put(actions.authorizeFailure(errorData.message));
    }
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getTiers(username: string) {
  try {
    const { data }: GetTiersResponse = yield call(getTiersAPI, username);
    yield put(actions.setTiers(data.tiers));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    if (errorData) {
      yield put(actions.authorizeFailure(errorData.message));
    }
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getPermissions() {
  const { accept }: auth.InitialState = yield select((state) => state.auth);
  try {
    const { data }: GetResourcesResponse = yield call(
      getPermissionsAPI,
      accept.profile?.role || ''
    );
    const resources = mappingAllowAccessResources(data.resources);
    const navigations = allowedNavigations(resources);
    let setNavigations: Navigation = navigations;
    if (accept.tiers.length === 0 && setNavigations.tier) {
      delete setNavigations.tier;
    }
    yield put(
      actions.setPermissions({
        resourceAccess: resources,
        navigations: setNavigations,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.authorizeFailure(errorData ? errorData.message : ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}

type ProfileAction =
  | ReturnType<typeof actions.setProfile>
  | ReturnType<typeof actions.authorizeFailure>;
type PermissionAction =
  | ReturnType<typeof actions.setProfile>
  | ReturnType<typeof actions.authorizeFailure>;
type TiersAction =
  | ReturnType<typeof actions.setTiers>
  | ReturnType<typeof actions.authorizeFailure>;

function* authorizeFlow() {
  const {
    router: { location },
  }: RootState = yield select((state) => state);
  yield fork(getProfile);
  const profileAction: ProfileAction = yield take([
    Types.setProfile,
    Types.authorizeFailure,
  ]);
  if (profileAction.type === Types.authorizeFailure) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    yield put(push({ pathname: '/login' }));
    return;
  }
  if (!profileAction.payload.username) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    yield put(push({ pathname: '/login' }));
    return;
  }
  yield fork(getTiers, profileAction.payload.username);
  const tiersAction: TiersAction = yield take([
    Types.setTiers,
    Types.authorizeFailure,
  ]);
  if (tiersAction.type === Types.authorizeFailure) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    yield put(push({ pathname: '/login' }));
    return;
  }
  yield fork(getPermissions);
  const permissionAction: PermissionAction = yield take([
    Types.setPermissions,
    Types.authorizeFailure,
  ]);
  if (permissionAction.type === Types.authorizeFailure) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    yield put(push({ pathname: '/login' }));
    return;
  }
  if (tiersAction.payload.length <= 0) {
    yield put(actions.authorizeSuccess());
    return;
  }
  const keys = (location?.pathname || '').split('/');
  const tierNameParam =
    keys[1] && keys[1] === 'tiers' && keys[2] !== undefined ? keys[2] : '';
  const getTierName = localStorage.getItem('tier');
  const isTierParam = tiersAction.payload.find(
    (item) => item.name === tierNameParam
  );
  const isTierGet = tiersAction.payload.find(
    (item) => item.name === getTierName
  );
  if (!isTierParam && !isTierGet) {
    let tier = tiersAction.payload[0];
    localStorage.setItem('tier', tier.name);
    yield put(actions.setTier(tier));
    yield put(actions.authorizeSuccess());
    return;
  }
  if (isTierParam) {
    localStorage.setItem('tier', isTierParam.name);
    yield put(actions.setTier(isTierParam));
  }
  if (!isTierParam && isTierGet) {
    localStorage.setItem('tier', isTierGet.name);
    yield put(actions.setTier(isTierGet));
  }
  yield put(actions.authorizeSuccess());
}
function* logout() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  yield put(actions.setTier(null));
  yield put(push({ pathname: '/login' }));
}
function* watchLogin() {
  yield takeLatest(actions.loginRequest, login);
}
function* watchAuthorize() {
  yield takeLatest(Types.authorizeRequest, authorizeFlow);
}
function* watchLogout() {
  yield takeLatest(actions.logoutRequest, logout);
}
function* sagas() {
  yield all([fork(watchLogin), fork(watchAuthorize), fork(watchLogout)]);
}
export default sagas;
