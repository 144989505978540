import {
  all,
  fork,
  put,
  call,
  takeLeading,
  takeLatest,
  select,
} from 'redux-saga/effects';
import API, { CancelToken, Canceler } from 'services/defaultInstance';
import * as actions from './index';
import {
  GetRivalPurchasePricesResponse,
  CreateRivalPurchasePrice,
  GetProductsResponse,
  UpdateRivalPurchasePrice,
  RivalPurchasePriceFilters,
  DefaultQueries,
} from 'models';
import { findErrorToData, setPageInfo } from 'utils';
import { addAlert } from 'store/notify';
import { RootState } from 'store/index';
let cancels: Canceler[] = [];

function* getPrices({ payload }: ReturnType<typeof actions.getPricesRequest>) {
  const {
    rivalPurchasePrices: { pricesList },
    auth: {
      accept: { tier },
    },
  }: RootState = yield select((state) => state);

  try {
    const { page, pageSize, startedAt, endedAt, ...payloadParams } = payload;
    let params: RivalPurchasePriceFilters & DefaultQueries = {
      ...payloadParams,
      pageSize: pageSize || pricesList.pageSize,
    };
    if (startedAt && !endedAt) {
      params.createdAt = startedAt;
    }
    if (startedAt && endedAt) {
      params.startedAt = startedAt;
      params.endedAt = endedAt;
    }
    const { data }: GetRivalPurchasePricesResponse = yield call(
      API.get,
      `/v1/tiers/${tier?.name || ''}/rivalPurchasePrices`,
      { params }
    );
    const pageInfo = setPageInfo(
      {
        page: pricesList.page,
        pageSize: pricesList.pageSize,
        pageTokens: pricesList.pageTokens,
      },
      {
        page: page,
        pageSize: pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    const dataPayload: actions.GetPricesSuccess = {
      ...pageInfo,
      prices: data.rivalPurchasePrices,
    };
    yield put(actions.getPricesSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getPricesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeZonesPage({
  payload,
}: ReturnType<typeof actions.onChangePricesPage>) {
  const {
    pricesList: { pageTokens, page },
  }: actions.InitialState = yield select((state) => state.rivalPurchasePrices);
  const { nextOrPrev, ...payloadParams } = payload;
  let params: RivalPurchasePriceFilters & DefaultQueries = {
    ...payloadParams,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  params.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    params.pageToken = pageToken;
  }
  yield put(actions.getPricesRequest(params));
}
function* createPrice({
  payload,
}: ReturnType<typeof actions.createPriceRequest>) {
  const {
    auth: {
      accept: { tier },
    },
    rivalPurchasePrices: {
      productsList: { products },
    },
  }: RootState = yield select((state) => state);
  const product = products.find((p) => p.title === payload.product.title);
  const price: CreateRivalPurchasePrice = {
    price: payload.price,
    rival: payload.rival,
    tier: tier?.name || '',
    product: {
      name: product?.name || '',
      title: product?.title || '',
    },
    remark: payload.remark || '',
  };
  try {
    yield call(API.post, `/v1/tiers/rivalPurchasePrices`, price, {
      cancelToken: new CancelToken((c) => cancels.push(c)),
    });
    yield put(actions.createPriceSuccess());
    cancels = [];
    yield put(actions.getPricesRequest({}));
    yield put(
      addAlert({
        message: 'ສ້າງສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createPriceFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updatePrice({
  payload,
}: ReturnType<typeof actions.updatePriceRequest>) {
  const {
    rivalPurchasePrices: {
      productsList: { products },
      updatePrice,
    },
  }: RootState = yield select((state) => state);
  const product = products.find((p) => p.title === payload.product.title);
  const price: UpdateRivalPurchasePrice = {
    price: payload.price,
    rival: payload.rival,
    product: {
      name: product?.name || '',
      title: product?.title || '',
    },
    id: updatePrice.price?.id || '',
    remark: payload.remark || '',
  };
  try {
    yield call(API.post, `/v1/tiers/rivalPurchasePrices-update`, price, {
      cancelToken: new CancelToken((c) => cancels.push(c)),
    });
    yield put(actions.updatePriceSuccess());
    cancels = [];
    yield put(actions.getPricesRequest({}));
    yield put(
      addAlert({
        message: 'ແກ້ໄຂສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updatePriceFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* openDialogForm({
  payload,
}: ReturnType<typeof actions.onChangeDialogId>) {
  const {
    auth: {
      accept: { resourceAccess },
    },
    rivalPurchasePrices: { productsList },
  }: RootState = yield select((state) => state);
  if (
    resourceAccess['products'].read &&
    productsList.products.length === 0 &&
    (payload === 'addPrice' || payload === 'updatePrice')
  ) {
    yield put(actions.getProductsRequest());
  }
}
function* getProducts() {
  try {
    const {
      data: { products },
    }: GetProductsResponse = yield call(API.get, '/v1/products?pageSize=250');
    yield put(actions.getProductsSuccess(products));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getProductsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetPrices() {
  yield takeLeading(actions.Types.getPricesRequest, getPrices);
}
function* watchOnChangePricesPage() {
  yield takeLatest(actions.Types.onChangePricesPage, onChangeZonesPage);
}
function* watchOnChangePricesPageSize() {
  yield takeLatest(
    actions.Types.onChangePricesPageSize,
    function* ({ payload }: ReturnType<typeof actions.onChangePricesPageSize>) {
      yield put(actions.getPricesRequest(payload));
    }
  );
}
function* watchCreatePrice() {
  yield takeLeading(actions.Types.createPriceRequest, createPrice);
}
function* watchOpenDialogForm() {
  yield takeLeading(actions.Types.onChangeDialogId, openDialogForm);
}
function* watchUpdatePrice() {
  yield takeLeading(actions.Types.updatePriceRequest, updatePrice);
}
function* watchGetProducts() {
  yield takeLatest(actions.Types.getProductsRequest, getProducts);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetPrices),
    fork(watchCreatePrice),
    fork(watchOnChangePricesPage),
    fork(watchOnChangePricesPageSize),
    fork(watchOpenDialogForm),
    fork(watchUpdatePrice),
    fork(watchGetProducts),
  ]);
}
export default saga;
