import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  DefaultQueries,
  Purchase,
  PurchasesQueries,
  PayPurchaseForm,
  WalletAccount,
  SummaryPurchase,
  PaymentTransportFeeForm,
  PaymentTransportFee,
} from 'models';

type DialogID =
  | 'create'
  | 'update'
  | 'payPurchase'
  | 'previewSummary'
  | 'confirmPayPurchase'
  | 'canceledPurchase'
  | 'paymentTransportFee'
  | null;
type Queries = DefaultQueries & PurchasesQueries;

export interface InitialState {
  purchases: {
    data: Purchase[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  form: {
    isCreating: boolean;
    isLoading: boolean;
    dialogID: DialogID;
    error: string | null;
    isAfterSubmitSuccess: boolean;
    purchase: null | Purchase;
    walletAccounts: WalletAccount[];
    summaries: SummaryPurchase[];
  };
  exportData: {
    isLoading: boolean;
  };
}
const initialState: InitialState = {
  purchases: {
    error: null,
    isLoading: false,
    data: [],
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
  form: {
    isCreating: false,
    isLoading: false,
    error: null,
    dialogID: null,
    isAfterSubmitSuccess: false,
    purchase: null,
    walletAccounts: [],
    summaries: [],
  },
  exportData: {
    isLoading: false,
  },
};

export enum Types {
  cancelRequestAPI = 'tier/purchases@cancelRequestAPI',
  onChangeDialogID = 'tier/purchases@onChangeDialogID',
  getPurchasesRequest = 'tier/purchases@getPurchasesRequest',
  getPurchasesSuccess = 'tier/purchases@getPurchasesSuccess',
  getPurchasesFailure = 'tier/purchases@getPurchasesFailure',
  purchasesOnChangePageSize = 'tier/purchases@purchasesOnChangePageSize',
  purchasesOnChangePage = 'tier/purchases@purchasesOnChangePage',
  afterSubmitSuccess = 'tier/purchases@afterSubmitSuccess',
  openForm = 'tier/purchases@openForm',
  openPayPurchaseFormRequest = 'tier/purchases@openPayPurchaseFormRequest',
  openPayPurchaseFormSuccess = 'tier/purchases@openPayPurchaseFormSuccess',
  openPayPurchaseFormFailure = 'tier/purchases@openPayPurchaseFormFailure',
  payPurchaseRequest = 'tier/purchases@payPurchaseRequest',
  payPurchaseSuccess = 'tier/purchases@payPurchaseSuccess',
  payPurchaseFailure = 'tier/purchases@payPurchaseFailure',
  openPreviewSummaryRequest = 'tier/purchases@openPreviewSummaryRequest',
  openPreviewSummarySuccess = 'tier/purchases@openPreviewSummarySuccess',
  openPreviewSummaryFailure = 'tier/purchases@openPreviewSummaryFailure',
  getWalletAccountsRequest = 'tier/purchases@getWalletAccountsRequest',
  getWalletAccountsSuccess = 'tier/purchases@getWalletAccountsSuccess',
  getWalletAccountsFailure = 'tier/purchases@getWalletAccountsFailure',
  createSummariesPurchasesRequest = 'tier/purchases@createSummariesPurchasesRequest',
  createSummariesPurchasesSuccess = 'tier/purchases@createSummariesPurchasesSuccess',
  createSummariesPurchasesFailure = 'tier/purchases@createSummariesPurchasesFailure',
  setPurchase = 'tier/purchases@setPurchase',
  getPurchaseRequest = 'tier/purchases@getPurchaseRequest',
  getPurchaseSuccess = 'tier/purchases@getPurchaseSuccess',
  getPurchaseFailure = 'tier/purchases@getPurchaseFailure',
  cancelPurchaseRequest = 'tier/purchases@cancelPurchaseRequest',
  cancelPurchaseSuccess = 'tier/purchases@cancelPurchaseSuccess',
  cancelPurchaseFailure = 'tier/purchases@cancelPurchaseFailure',
  paymentTransportRequest = 'tier/purchases@paymentTransportRequest',
  paymentTransportSuccess = 'tier/purchases@paymentTransportSuccess',
  paymentTransportFailure = 'tier/purchases@paymentTransportFailure',
  exportCsvRequest = 'tier/purchases@exportCsvRequest',
  exportCsvSuccess = 'tier/purchases@exportCsvSuccess',
  exportCsvFailure = 'tier/purchases@exportCsvFailure',
}

export type GetPurchasesSuccess = Queries & {
  data: Purchase[];
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const onChangeDialogID = createAction<DialogID>(Types.onChangeDialogID);

export const getPurchasesRequest = createAction<Queries>(
  Types.getPurchasesRequest
);
export const getPurchasesSuccess = createAction<
  GetPurchasesSuccess,
  Types.getPurchasesSuccess
>(Types.getPurchasesSuccess);
export const getPurchasesFailure = createAction<
  string | null,
  Types.getPurchasesFailure
>(Types.getPurchasesFailure);
export const purchasesOnChangePageSize = createAction<
  Queries,
  Types.purchasesOnChangePageSize
>(Types.purchasesOnChangePageSize);
export const purchasesOnChangePage = createAction<
  Queries,
  Types.purchasesOnChangePage
>(Types.purchasesOnChangePage);

export const afterSubmitSuccess = createAction(Types.afterSubmitSuccess);
export const openForm = createAction<DialogID>(Types.openForm);
export const openPayPurchaseFormRequest = createAction<Purchase>(
  Types.openPayPurchaseFormRequest
);
export const openPayPurchaseFormSuccess = createAction<Purchase>(
  Types.openPayPurchaseFormSuccess
);
export const openPayPurchaseFormFailure = createAction<string | null>(
  Types.openPayPurchaseFormFailure
);
export const getWalletAccountsRequest = createAction(
  Types.getWalletAccountsRequest
);
export const getWalletAccountsSuccess = createAction<
  WalletAccount[],
  Types.getWalletAccountsSuccess
>(Types.getWalletAccountsSuccess);
export const getWalletAccountsFailure = createAction<
  string | null,
  Types.getWalletAccountsFailure
>(Types.getWalletAccountsFailure);
export const payPurchaseRequest = createAction<PayPurchaseForm>(
  Types.payPurchaseRequest
);
export const payPurchaseSuccess = createAction(Types.payPurchaseSuccess);
export const payPurchaseFailure = createAction<string | null>(
  Types.payPurchaseFailure
);
export const openPreviewSummaryRequest = createAction<string>(
  Types.openPreviewSummaryRequest
);
export const openPreviewSummarySuccess = createAction<SummaryPurchase[]>(
  Types.openPreviewSummarySuccess
);
export const openPreviewSummaryFailure = createAction<string | null>(
  Types.openPreviewSummaryFailure
);
type CreateForm = {
  description?: string;
  purchasedAt: string;
};
export const createSummariesPurchasesRequest = createAction<CreateForm>(
  Types.createSummariesPurchasesRequest
);
export const createSummariesPurchasesSuccess = createAction(
  Types.createSummariesPurchasesSuccess
);
export const createSummariesPurchasesFailure = createAction<string | null>(
  Types.createSummariesPurchasesFailure
);
export const setPurchase = createAction<Purchase>(Types.setPurchase);
export const getPurchaseRequest = createAction<string>(
  Types.getPurchaseRequest
);
export const getPurchaseSuccess = createAction<Purchase>(
  Types.getPurchaseSuccess
);
export const getPurchaseFailure = createAction<string | null>(
  Types.getPurchaseFailure
);
export const cancelPurchaseRequest = createAction(Types.cancelPurchaseRequest);
export const cancelPurchaseSuccess = createAction(Types.cancelPurchaseSuccess);
export const cancelPurchaseFailure = createAction<string | null>(
  Types.cancelPurchaseFailure
);
export const paymentTransportRequest = createAction<PaymentTransportFeeForm>(
  Types.paymentTransportRequest
);
export const paymentTransportSuccess = createAction<PaymentTransportFee>(
  Types.paymentTransportSuccess
);
export const paymentTransportFailure = createAction<string>(
  Types.paymentTransportFailure
);
export const exportCsvRequest = createAction<Queries>(Types.exportCsvRequest);
export const exportCsvSuccess = createAction(Types.exportCsvSuccess);
export const exportCsvFailure = createAction(Types.exportCsvFailure);

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    state.form.isCreating = false;
    state.form.isLoading = false;
    state.purchases.isLoading = false;
  });
  builder.addCase(onChangeDialogID, (state, action) => {
    state.form.dialogID = action.payload;
  });
  builder.addCase(getPurchasesRequest, (state) => {
    state.purchases.isLoading = true;
    state.purchases.error = null;
    state.form.isAfterSubmitSuccess = false;
  });
  builder.addCase(getPurchasesSuccess, (state, action) => {
    return {
      ...state,
      purchases: {
        ...state.purchases,
        isLoading: false,
        error: null,
        ...action.payload,
      },
    };
  });
  builder.addCase(getPurchasesFailure, (state, action) => {
    return {
      ...state,
      purchases: {
        ...state.purchases,
        isLoading: false,
        error: action.payload,
      },
    };
  });
  builder.addCase(afterSubmitSuccess, (state) => {
    state.form.isAfterSubmitSuccess = false;
    state.form.dialogID = null;
  });
  builder.addCase(openForm, (state, { payload }) => {
    state.form.dialogID = payload;
  });
  builder.addCase(openPayPurchaseFormSuccess, (state, { payload }) => {
    state.form.dialogID = 'payPurchase';
    state.form.purchase = payload;
  });
  builder.addCase(openPayPurchaseFormFailure, (state, { payload }) => {
    state.form.error = payload;
  });
  builder.addCase(getWalletAccountsRequest, (state) => {
    state.form.isLoading = true;
  });
  builder.addCase(getWalletAccountsSuccess, (state, { payload }) => {
    state.form.walletAccounts = payload;
    state.form.isLoading = false;
  });
  builder.addCase(payPurchaseRequest, (state) => {
    state.form.isCreating = true;
    state.form.error = null;
  });
  builder.addCase(payPurchaseSuccess, (state) => {
    state.form.isCreating = false;
    state.form.isAfterSubmitSuccess = true;
    state.form.dialogID = null;
  });
  builder.addCase(payPurchaseFailure, (state, action) => {
    return {
      ...state,
      form: { ...state.form, isCreating: false, error: action.payload },
    };
  });
  builder.addCase(openPreviewSummaryRequest, (state) => {
    state.form.isLoading = true;
    state.form.error = null;
  });
  builder.addCase(openPreviewSummarySuccess, (state, { payload }) => {
    state.form.isLoading = false;
    state.form.summaries = payload;
    state.form.dialogID = 'previewSummary';
  });
  builder.addCase(openPreviewSummaryFailure, (state, { payload }) => {
    state.form.isLoading = false;
    state.form.error = payload;
    state.form.dialogID = null;
    state.form.summaries = [];
  });
  builder.addCase(createSummariesPurchasesRequest, (state) => {
    state.form.isCreating = true;
    state.form.error = null;
  });
  builder.addCase(createSummariesPurchasesSuccess, (state) => {
    state.form.isCreating = false;
    state.form.isAfterSubmitSuccess = true;
    state.form.summaries = [];
    state.form.dialogID = null;
  });
  builder.addCase(createSummariesPurchasesFailure, (state, { payload }) => {
    state.form.isCreating = false;
    state.form.error = payload;
  });
  builder.addCase(setPurchase, (state, { payload }) => {
    state.form.purchase = payload;
  });
  builder.addCase(getPurchaseRequest, (state) => {
    state.form.isLoading = true;
    state.form.error = null;
  });
  builder.addCase(getPurchaseSuccess, (state, { payload }) => {
    state.form.isLoading = false;
    state.form.purchase = payload;
  });
  builder.addCase(getPurchaseFailure, (state, { payload }) => {
    state.form.isLoading = false;
    state.form.error = payload;
  });
  builder.addCase(cancelPurchaseRequest, (state) => {
    state.form.isCreating = true;
    state.form.error = null;
  });
  builder.addCase(cancelPurchaseSuccess, (state) => {
    state.form.isCreating = false;
    state.purchases.data = state.purchases.data.map((purchase) => {
      if (purchase.id === state.form.purchase?.id) {
        return {
          ...purchase,
          status: 'CANCEL',
        };
      }
      return purchase;
    });
    state.form.purchase = null;
    state.form.dialogID = null;
  });
  builder.addCase(cancelPurchaseFailure, (state, { payload }) => {
    state.form.isCreating = false;
    state.form.error = payload;
  });
  builder.addCase(paymentTransportRequest, (state) => {
    state.form.isCreating = true;
    state.form.error = null;
  });
  builder.addCase(paymentTransportSuccess, (state, { payload }) => {
    state.form.isCreating = false;
    state.form.dialogID = null;
    if (state.form.purchase) {
      state.form.purchase = {
        ...state.form.purchase,
        paymentTransportFeeId: payload.referenceId,
        transportFee: payload.transportFee,
      };
    }
  });
  builder.addCase(paymentTransportFailure, (state, { payload }) => {
    state.form.isCreating = false;
    state.form.error = payload;
  });
  builder
    .addCase(exportCsvRequest, (state) => {
      state.exportData.isLoading = true;
    })
    .addCase(exportCsvSuccess, (state) => {
      state.exportData.isLoading = false;
    })
    .addCase(exportCsvFailure, (state) => {
      state.exportData.isLoading = false;
    });
});

export default reducer;
