import {
  ShoppingCartCheckout,
  WatchLater,
  MonetizationOn,
  Home,
  Settings,
  SolarPower,
  CurrencyExchange,
  Store,
  SupervisorAccount,
  Groups,
  AccountCircle,
  AccountBalanceWallet,
  PriceChange,
  ReceiptLong,
  LocationCity,
  Assessment,
  Dashboard,
  AcUnit,
} from '@mui/icons-material';
import moment from 'moment';
import { ResourceKey } from 'utils';

export const icons = {
  ShoppingCartCheckout,
  WatchLater,
  Dashboard,
  Assessment,
  ReceiptLong,
  MonetizationOn,
  Home,
  Settings,
  SolarPower,
  CurrencyExchange,
  Store,
  SupervisorAccount,
  Groups,
  AccountCircle,
  AccountBalanceWallet,
  PriceChange,
  LocationCity,
  AcUnit,
};
export type IconName = keyof typeof icons;
/**
 * 0 is local
 * 1 is development
 * 2 is production
 */
export type NavigationItem = {
  id: string;
  name: string;
  icon?: IconName;
  to?: string;
  children?: NavigationItem[];
  resource?: ResourceKey | '*';
  mode: 0 | 1 | 2;
};

const navigationKeys = {
  tier: 'tier',
  superAdmin: 'superAdmin',
  admin: 'admin',
  // app: 'app',
} as const;
export type NavigationKey =
  (typeof navigationKeys)[keyof typeof navigationKeys];

export type Navigation = {
  [k in NavigationKey]?: {
    title: string;
    items: NavigationItem[];
  };
};
const startedAt = moment().add(-1, 'M').format();
const endedAt = moment().format();
const navigations: Navigation = {
  'superAdmin': {
    //ສຳນັກງານໃຫຍ່ => Super admin
    title: 'ສຳນັກງານໃຫຍ່',
    items: [
      // {
      //   id: 'input',
      //   name: 'input',
      //   icon: 'SolarPower',
      //   to: '/input',
      //   resource: '*',
      // },
      //TODO : resource name not found
      {
        id: 'super-admin-dashboard',
        name: 'ພາບລວມ',
        icon: 'Dashboard',
        mode: 2,
        children: [
          {
            id: 'super-admin-dashboard-home',
            name: 'ພາບລວມ',
            to: '/super-admin/dashboard/home',
            resource: 'admin-report-inventory',
            mode: 2,
          },
          // {
          //   id: 'super-admin-dashboard-lots',
          //   name: 'ປະລິມານລ໋ອດສິນຄ້າ',
          //   to: '/super-admin/dashboard/lots',
          //   resource: 'admin-report-inventory',
          //   mode: 2,
          // },
          {
            id: 'super-admin-dashboard-purchases',
            name: 'ການຊື້',
            to: '/super-admin/dashboard/purchases',
            resource: 'admin-report-purchase',
            mode: 2,
          },
          {
            id: 'super-admin-dashboard-salse',
            name: 'ການຂາຍ',
            to: '/super-admin/dashboard/sales',
            resource: 'admin-report-sale',
            mode: 2,
          },
        ],
      },
      {
        id: 'company',
        name: 'ຂໍ້ມູນບໍລິສັດ',
        icon: 'LocationCity',
        to: '/super-admin/company',
        resource: 'companies',
        mode: 2,
      },
      {
        id: 'activity-logging',
        name: 'ບັນທຶກກິດຈະກຳການໃຊ້ງານ',
        icon: 'WatchLater',
        to: '/super-admin/activities',
        resource: 'activities',
        mode: 2,
      },
      {
        id: 'super-admin-predict',
        name: 'ຕັ້ງຄ່າລາຄາຄວາມຊຸ່ມ',
        to: '/super-admin/predict',
        resource: 'admin-predict',
        mode: 2,
        icon: 'AcUnit',
      },
      {
        id: 'super-admin-product-price',
        name: 'ອະນຸມັດລາຄາສິນຄ້າ',
        to: '/super-admin/product-prices/pending-approve',
        resource: 'admin-purchasePrices',
        icon: 'MonetizationOn',
        mode: 2,
      },

      {
        id: 'super-admin-exchange-rates',
        name: 'ອັດຕາແລກປ່ຽນ',
        to: '/super-admin/exchange-rates',
        icon: 'CurrencyExchange',
        resource: 'exchangeRates',
        mode: 2,
      },
      {
        id: 'super-admin-users',
        name: 'ຂໍ້ມູນຜູ້ໃຊ້',
        to: '/super-admin/users',
        icon: 'AccountCircle',
        resource: 'admin-users',
        mode: 2,
      },
      {
        id: 'super-admin-customers',
        name: 'ຂໍ້ມູນລູກຄ້າ',
        to: '/super-admin/customers',
        resource: 'admin-customers',
        icon: 'Groups',
        mode: 2,
      },
      {
        id: 'super-admin-expense-types',
        name: 'ຂໍ້ມູນປະເພດລາຍຈ່າຍ',
        to: '/super-admin/expense-types',
        resource: 'expenseTypes',
        icon: 'ReceiptLong',
        mode: 2,
      },
      {
        id: 'super-admin-report-purchases',
        name: 'ລາຍງານການຊື້',
        icon: 'ShoppingCartCheckout',
        mode: 2,
        children: [
          {
            id: 'super-admin-purchases-summaries',
            name: 'ລາຍງານປະລິມານການຊື້',
            to: `/super-admin/report/purchases-summaries?timeline=MONTH&keyName=weight&startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: 'admin-report-purchase',
            mode: 2,
          },
          {
            id: 'super-admin-purchases-transactions',
            name: 'ລາຍງານຕາມລາຄາຄວາມຊຸ່ມ',
            to: `/super-admin/report/purchases-transactions`,
            resource: 'admin-report-purchase',
            mode: 2,
          },
          // {
          //   id: 'super-admin-purchases-amount',
          //   name: 'ປະລິມານການຊື້ສຸດທິ ແລະ ລາຄາ',
          //   to: '/super-admin/report/purchases-amount',
          //   resource: 'admin-report-purchase',
          //   mode: 2,
          // },
          // {
          //   id: 'super-admin-purchases-customers',
          //   name: 'ລາຍງານການຊື້ແຍກຕາມລູກຄ້າ',
          //   to: '/super-admin/report/purchases-customers',
          //   resource: 'admin-report-purchase',
          //   mode: 2,
          // },
          // {
          //   id: 'super-admin-purchases-paymentType',
          //   name: 'ລາຍງານການຊື້ແຍກຕາມການຈ່າຍ',
          //   to: '/super-admin/report/purchases-paymentType',
          //   resource: 'admin-report-purchase',
          //   mode: 2,
          // },
          // {
          //   id: 'super-admin-purchases-moisturePercent',
          //   name: 'ລາຍງານການຊື້ແຍກຕາມຄວາມຊຸ່ມ',
          //   to: '/super-admin/report/purchases-moisturePercent',
          //   resource: 'admin-report-purchase',
          //   mode: 2,
          // },
        ],
      },
      {
        id: 'super-admin-report-inventories',
        name: 'ລາຍງານການແປຮູບສິນຄ້າ',
        icon: 'SolarPower',
        mode: 2,
        children: [
          {
            id: 'super-admin-inventories-stocks-summary',
            name: 'ລາຍງານຮັບຊື້ສິນຄ້າ (stocks)',
            to: `/super-admin/report/inventories-stocks-summary?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: 'admin-report-inventory',
            mode: 2,
          },
          {
            id: 'super-admin-inventories-lots-summary',
            name: 'ລາຍງານລາຍການລ໋ອດ',
            to: `/super-admin/report/inventories-lots-summary?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: 'admin-report-inventory',
            mode: 2,
          },
        ],
      },
      {
        id: 'super-admin-report-sales',
        name: 'ລາຍງານການຂາຍ',
        icon: 'Store',
        mode: 2,
        children: [
          {
            id: 'super-admin-sales',
            name: 'ລາຍງານປະລິມານການຂາຍ',
            to: `/super-admin/report/sales?timeline=MONTH&keyName=weight&startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: 'admin-report-sale',
            mode: 2,
          },
          // {
          //   id: 'super-admin-sales',
          //   name: 'ລາຍງານປະລິມານການຂາຍ ແລະ ຄຸນນະພາບ',
          //   to: `/super-admin/report/sales-quality-check?timeline=MONTH&keyName=amount&startedAt=${encodeURIComponent(
          //     startedAt
          //   )}&endedAt=${encodeURIComponent(endedAt)}`,
          //   resource: 'admin-report-sale',
          //   mode: 1,
          // },
          // {
          //   id: 'super-admin-sales-volumeKg',
          //   name: 'ລາຍງານປະລິມານການຂາຍສຸດທິ',
          //   to: '/super-admin/report/sales-volumeKg',
          //   resource: 'admin-report-sale',
          //   mode: 2,
          // },
          // {
          //   id: 'super-admin-sales-amount',
          //   name: 'ປະລິມານການຂາຍສຸດທິ ແລະ ລາຄາ',
          //   to: '/super-admin/report/sales-amount',
          //   resource: 'admin-report-sale',
          //   mode: 2,
          // },
          // {
          //   id: 'super-admin-sales-moisturePercent',
          //   name: 'ການຂາຍແຍກຕາມຄວາມຊຸ່ມຊື້',
          //   to: '/super-admin/report/sales-moisturePercent',
          //   resource: 'admin-report-sale',
          //   mode: 2,
          // },
          {
            id: 'super-admin-sales-invoices',
            name: 'ລາຍງານການອອກໃບຮຽກເກັບເງິນ',
            to: `/super-admin/report/sales-invoices/summary?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: 'admin-report-sale',
            mode: 2,
          },
          {
            id: 'super-admin-sales-receipts',
            name: 'ລາຍງານການອອກໃບຮັບເງິນ',
            to: `/super-admin/report/sales-receipts/summary?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: 'admin-report-sale',
            mode: 2,
          },
        ],
      },

      {
        id: 'super-admin-report-expenses',
        name: 'ລາຍງານລາຍຈ່າຍ',
        icon: 'Assessment',
        mode: 2,
        children: [
          {
            id: 'super-admin-expenses-summary',
            name: 'ລາຍງານຕາມປະເພດລາຍຈ່າຍ',
            to: `/super-admin/report/expenses-summary?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: 'admin-report-inventory',
            mode: 2,
          },
          // TODO : not name resource
          {
            id: 'super-admin-wallets',
            name: 'ລາຍງານກະເປົາເງິນ',
            to: `/super-admin/report/wallets-accounts`,
            resource: 'admin-report-inventory',
            mode: 2,
          },
        ],
      },

      {
        id: 'super-admin-setting',
        name: 'ຕັ້ງຄ່າ',
        icon: 'Settings',
        mode: 2,
        children: [
          {
            id: 'super-admin-zones',
            name: 'ຈັດການຂໍ້ມູນໂຊນ',
            to: '/super-admin/zones',
            resource: 'admin-zones',
            mode: 2,
          },
          {
            id: 'super-admin-tiers',
            name: 'ຈັດການຂໍ້ມູນລານ',
            to: '/super-admin/tiers',
            resource: 'admin-tiers',
            mode: 2,
          },
          {
            id: 'super-admin-wallets',
            name: 'ຈັດການຂໍ້ມູນກະເປົາເງິນ',
            to: '/super-admin/wallets',
            resource: 'admin-wallets',
            mode: 2,
          },
          {
            id: 'super-admin-seasons',
            name: 'ຈັດການຂໍ້ມູນລະດູການ',
            to: '/super-admin/seasons',
            resource: 'seasons',
            mode: 2,
          },
          {
            id: 'super-admin-products',
            name: 'ຂໍ້ມູນສິນຄ້າ',
            to: '/super-admin/products',
            resource: 'products',
            mode: 2,
          },
          {
            id: 'super-admin-roles',
            name: 'ສິດທິ ແລະ ການເຂົ້າເຖິງ',
            to: '/super-admin/roles',
            resource: 'admin-roles',
            mode: 2,
          },
        ],
      },
    ],
  },
  'admin': {
    //ສຳນັກງານ => Admin
    title: 'ໂຊນ',
    items: [
      // {
      //   id: 'cassava-price-today-admin',
      //   name: 'ອະນຸມັດລາຄາສິນຄ້າ',
      //   icon: 'MonetizationOn',
      //   to: '/admin/product-prices',
      //   resource: 'zone-purchasePrices',
      //   mode: 2,
      // },
    ],
  },
  'tier': {
    //ລານ => Tier
    title: 'ລານ',
    items: [
      {
        id: 'tier-menu',
        name: 'ໜ້າຫຼັກ',
        icon: 'Home',
        to: '/tiers/:tierName/menu',
        resource: '*',
        mode: 2,
      },
      // {
      //   id: 'tier-dashboard',
      //   name: 'ພາບລວມ',
      //   icon: <Dashboard />,
      //   to: '/tiers/:tierName/dashboard',
      // },
      {
        id: 'tier-product-price',
        name: 'ຕັ້ງລາຄາສິນຄ້າ',
        to: '/tiers/:tierName/moisture-product-price/pending-approve',
        resource: 'purchasePrices',
        icon: 'MonetizationOn',
        mode: 2,
      },
      {
        id: 'tier-rival-purchase-prices',
        name: 'ລາຄາມັນລານຄູ່ແຂ່ງ',
        to: '/tiers/:tierName/rival-purchase-prices',
        resource: 'rivalPurchasePrices',
        icon: 'PriceChange',
        mode: 2,
      },
      {
        id: 'tier-wallet',
        name: 'ກະເປົາເງິນ',
        to: '/tiers/:tierName/wallets',
        icon: 'AccountBalanceWallet',
        resource: 'wallets',
        mode: 2,
      },
      {
        id: 'expenses',
        name: 'ຂໍ້ມູນລາຍຈ່າຍ',
        to: '/tiers/:tierName/expenses',
        resource: 'expenses',
        icon: 'ReceiptLong',
        mode: 2,
      },
      {
        id: 'tier-customers',
        name: 'ຂໍ້ມູນລູກຄ້າ',
        icon: 'Groups',
        to: '/tiers/:tierName/customers',
        resource: 'customers',
        mode: 2,
      },
      {
        id: 'tier-purchasing-product',
        name: 'ຈັດການຮັບຊື້ສິນຄ້າ',
        icon: 'ShoppingCartCheckout',
        mode: 2,
        children: [
          {
            id: 'tier-purchasing-purchases',
            name: 'ຮັບຊື້ສິນຄ້າ',
            to: `/tiers/:tierName/purchases?startedAt=${encodeURIComponent(
              moment().format()
            )}`,
            resource: 'purchases',
            mode: 2,
          },
          {
            id: 'tier-PurchasesSummaries',
            name: 'ຍອດສັ່ງຊື້ສິນຄ້າ',
            to: '/tiers/:tierName/purchases-summaries',
            resource: 'purchases',
            mode: 2,
          },
        ],
      },
      {
        id: 'tier-inventory',
        name: 'ຈັດການແປຮູບສິນຄ້າ',
        icon: 'SolarPower',
        mode: 2,
        children: [
          {
            id: 'tier-inventory-stocks',
            name: 'ລາຍການປະລິມານການຊື້ມັນສະສົມ',
            to: '/tiers/:tierName/inventory/stocks',
            resource: 'inventories',
            mode: 2,
          },
          {
            id: 'tier-inventory-lots',
            name: 'ລາຍການລ໋ອດ',
            to: '/tiers/:tierName/inventory/lots',
            resource: 'inventories',
            mode: 2,
          },
        ],
      },
      {
        id: 'tier-sale',
        name: 'ຈັດການການຂາຍ',
        icon: 'Store',
        mode: 2,
        children: [
          {
            id: 'tier-sale-orders',
            name: 'ໃບສັ່ງຊື້ (PO)',
            to: `/tiers/:tierName/sale/orders?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}&orderBy=ASC`,
            resource: 'sales',
            mode: 2,
          },
          {
            id: 'ໃບຮຽກເກັບເງິນ (Invoice)',
            name: 'ໃບຮຽກເກັບເງິນ (Invoice)',
            to: `/tiers/:tierName/sale/invoices?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}&orderBy=ASC`,
            resource: 'sales',
            mode: 2,
          },
          {
            id: 'tier-wallet',
            name: 'ລາຍການການຂາຍ (sync)',
            to: `/tiers/:tierName/sale/transactions?startedAt=${encodeURIComponent(
              endedAt
            )}&orderBy=ASC`,
            resource: 'sales',
            mode: 2,
          },

          {
            id: 'ໃບໂອນ (Transfer slip)',
            name: 'ໃບໂອນ (Transfer slip)',
            to: `/tiers/:tierName/sale/transfers?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}&orderBy=ASC`,
            resource: 'sales',
            mode: 2,
          },
          {
            id: 'ໃບຮັບເງິນ (Receipt)',
            name: 'ໃບຮັບເງິນ (Receipt)',
            to: `/tiers/:tierName/sale/receipts?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}&orderBy=ASC`,
            resource: 'sales',
            mode: 2,
          },
        ],
      },
    ],
  },
};
export default navigations;
