import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API, { Canceler, CancelToken } from 'services/defaultInstance';
import * as actions from './index';
import { GetCustomersResponse } from 'models';
import { findErrorToData } from 'utils';
import moment from 'moment';

let cancels: Canceler[] = [];
function* getProducts({
  payload,
}: ReturnType<typeof actions.getCustomersRequest>) {
  const {
    customersList: { pageTokens, pageSize },
  }: actions.InitialState = yield select((state) => state.superAdmin.customers);
  let query = `pageSize=${payload.pageSize ? payload.pageSize : pageSize}`;
  if (payload.pageToken) {
    query += `&pageToken=${payload.pageToken}`;
  }
  if (payload.createdAt) {
    query += `&startAt=${encodeURIComponent(
      moment(payload.createdAt).format('YYYY-MM-DDTHH:mm:ssZ')
    )})}`;
  }
  let dataPayload: actions.GetCustomersSuccess = {
    customers: [],
    pageTokens: [...pageTokens],
  };
  try {
    const { data }: GetCustomersResponse = yield call(
      API.get,
      `/v1/admin/tier/customers?${query}`,
      {
        cancelToken: new CancelToken((c) => cancels.push(c)),
      }
    );
    dataPayload.customers = data.customers;
    const pageToken = pageTokens.find((p) => p === data.nextPageToken);
    if (
      !pageToken &&
      dataPayload.pageTokens &&
      data.nextPageToken.length !== 0
    ) {
      dataPayload.pageTokens.push(data.nextPageToken);
    }
    if (payload.page) {
      dataPayload.page = payload.page;
    }
    if (payload.pageSize && payload.pageSize !== pageSize) {
      dataPayload.pageTokens =
        data.nextPageToken.length === 0 ? [] : [data.nextPageToken];
      dataPayload.pageSize = payload.pageSize;
      dataPayload.page = 1;
    }
    cancels = [];
    yield put(actions.getCustomersSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getCustomersFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeCustomersPage({
  payload,
}: ReturnType<typeof actions.onChangeCustomersPage>) {
  const {
    customersList: { pageTokens, page },
  }: actions.InitialState = yield select((state) => state.superAdmin.customers);
  let cloneFilters: any = {
    ...payload,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  cloneFilters.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    cloneFilters.pageToken = pageToken;
  }
  yield put(actions.getCustomersRequest(cloneFilters));
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetProducts() {
  yield takeLatest(actions.Types.getCustomersRequest, getProducts);
}
function* watchOnChangeCustomersPage() {
  yield takeLatest(actions.Types.onChangeCustomersPage, onChangeCustomersPage);
}
function* watchOnChangeCustomersPageSize() {
  yield takeLatest(
    actions.Types.onChangeCustomersPageSize,
    function* ({
      payload,
    }: ReturnType<typeof actions.onChangeCustomersPageSize>) {
      yield put(actions.getCustomersRequest(payload));
    }
  );
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetProducts),
    fork(watchOnChangeCustomersPage),
    fork(watchOnChangeCustomersPageSize),
  ]);
}
export default saga;
