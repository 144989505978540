import { createReducer, createAction } from '@reduxjs/toolkit';
import { Stock } from 'models';
import type { LotForm, Lot, LotsQueries } from 'models/lots/Repository';

type DialogID = null | 'createLotForm';
type Queries = LotsQueries & {};

export interface InitialState {
  lotsList: {
    data: Lot[];
    page: number;
    pageSize: number;
    pageTokens: {
      [key: number]: string;
    };
    isLoading: boolean;
    error: string | null;
  };
  dialogID: DialogID;
  lotCreate: {
    isLoading: boolean;
    error: string | null;
    isCreating: boolean;
  };
  lot: {
    isLoading: boolean;
    error: string | null;
    data: Lot | null;
    stocks: Stock[];
    isCreating: boolean;
  };
  exportData: {
    isLoading: boolean;
  };
}
const initialState: InitialState = {
  lotsList: {
    data: [],
    page: 1,
    pageSize: 25,
    pageTokens: {},
    isLoading: false,
    error: null,
  },
  dialogID: null,
  lotCreate: {
    isLoading: false,
    error: null,
    isCreating: false,
  },
  lot: {
    isLoading: false,
    error: null,
    data: null,
    stocks: [],
    isCreating: false,
  },
  exportData: {
    isLoading: false,
  },
};
export enum Types {
  cancelRequestAPI = 'tier/inventory/lots@cancelRequestAPI',
  onChangeDialogID = 'tier/inventory/lots@onChangeDialogID',

  getLotsRequest = 'tier/inventory/lots@getLotsRequest',
  getLotsSuccess = 'tier/inventory/lots@getLotsSuccess',
  getLotsFailure = 'tier/inventory/lots@getLotsFailure',
  lotsOnChangePageSize = 'tier/inventory/lots@lotsOnChangePageSize',
  lotsOnChangePage = 'tier/inventory/lots@lotsOnChangePage',
  createLotRequest = 'tier/inventory/lots@createLotRequest',
  createLotSuccess = 'tier/inventory/lots@createLotSuccess',
  createLotFailure = 'tier/inventory/lots@createLotFailure',
  getLotRequest = 'tier/inventory/lots@getLotRequest',
  getLotSuccess = 'tier/inventory/lots@getLotSuccess',
  getLotFailure = 'tier/inventory/lots@getLotFailure',
  getStocksSuccess = 'tier/inventory/lots@getStocksSuccess',
  getStocksFailure = 'tier/inventory/lots@getStocksFailure',
  closeLotRequest = 'tier/inventory/lots@closeLotRequest',
  closeLotSuccess = 'tier/inventory/lots@closeLotSuccess',
  closeLotFailure = 'tier/inventory/lots@closeLotFailure',
  exportCsvRequest = 'tier/inventory/lots@exportCsvRequest',
  exportCsvSuccess = 'tier/inventory/lots@exportCsvSuccess',
  exportCsvFailure = 'tier/inventory/lots@exportCsvFailure',
}

export type GetLotsSuccess = Queries & {
  data: Lot[];
  page?: number;
  pageSize?: number;
  pageTokens?: {
    [key: number]: string;
  };
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const onChangeDialogID = createAction<DialogID>(Types.onChangeDialogID);
export const getLotsRequest = createAction<Queries>(Types.getLotsRequest);
export const getLotsSuccess = createAction<GetLotsSuccess>(
  Types.getLotsSuccess
);
export const lotsOnChangePageSize = createAction<
  Queries,
  Types.lotsOnChangePageSize
>(Types.lotsOnChangePageSize);
export const lotsOnChangePage = createAction<Queries, Types.lotsOnChangePage>(
  Types.lotsOnChangePage
);
export const getLotsFailure = createAction<string>(Types.getLotsFailure);
export const createLotRequest = createAction<LotForm>(Types.createLotRequest);
export const createLotSuccess = createAction(Types.createLotSuccess);
export const createLotFailure = createAction<string>(Types.createLotFailure);
export const getLotRequest = createAction<string>(Types.getLotRequest);
export const getLotSuccess = createAction<Lot>(Types.getLotSuccess);
export const getLotFailure = createAction<string>(Types.getLotFailure);
export const getStocksSuccess = createAction<Stock[]>(Types.getStocksSuccess);
export const getStocksFailure = createAction<string>(Types.getStocksFailure);
export const closeLotRequest = createAction(Types.closeLotRequest);
export const closeLotSuccess = createAction(Types.closeLotSuccess);
export const closeLotFailure = createAction<string>(Types.closeLotFailure);
export const exportCsvRequest = createAction<Queries>(Types.exportCsvRequest);
export const exportCsvSuccess = createAction(Types.exportCsvSuccess);
export const exportCsvFailure = createAction(Types.exportCsvFailure);

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    state.lotsList.isLoading = false;
    state.dialogID = null;
  });
  builder.addCase(onChangeDialogID, (state, action) => {
    state.dialogID = action.payload;
  });
  builder.addCase(getLotsRequest, (state) => {
    state.lotsList.isLoading = true;
    state.lotsList.error = null;
  });
  builder.addCase(getLotsSuccess, (state, action) => {
    return {
      ...state,
      lotsList: {
        ...state.lotsList,
        ...action.payload,
        isLoading: false,
      },
    };
  });
  builder.addCase(getLotsFailure, (state, action) => {
    state.lotsList.isLoading = false;
    state.lotsList.error = action.payload;
  });
  builder.addCase(createLotRequest, (state) => {
    state.lotCreate.isCreating = true;
    state.lotCreate.error = null;
  });
  builder.addCase(createLotSuccess, (state) => {
    state.lotCreate.isCreating = false;
    state.dialogID = null;
  });
  builder.addCase(createLotFailure, (state, action) => {
    state.lotCreate.isCreating = false;
    state.lotCreate.error = action.payload;
  });
  builder.addCase(getLotRequest, (state) => {
    state.lot.isLoading = true;
    state.lot.error = null;
  });
  builder.addCase(getLotSuccess, (state, action) => {
    state.lot.data = action.payload;
  });
  builder.addCase(getLotFailure, (state, action) => {
    state.lot.isLoading = false;
    state.lot.error = action.payload;
  });
  builder.addCase(getStocksSuccess, (state, action) => {
    state.lot.stocks = action.payload;
    state.lot.isLoading = false;
  });
  builder.addCase(getStocksFailure, (state, action) => {
    state.lot.isLoading = false;
    state.lot.error = action.payload;
  });
  builder.addCase(closeLotRequest, (state) => {
    state.lot.isCreating = true;
    state.lot.error = null;
  });
  builder.addCase(closeLotSuccess, (state) => {
    state.lot.isCreating = false;
    if (state.lot.data) {
      state.lot.data = {
        ...state.lot.data,
        status: 'SOLDOUT',
      };
    }
  });
  builder.addCase(closeLotFailure, (state, action) => {
    state.lot.isCreating = false;
    state.lot.error = action.payload;
  });
  builder
    .addCase(exportCsvRequest, (state) => {
      state.exportData.isLoading = true;
    })
    .addCase(exportCsvSuccess, (state) => {
      state.exportData.isLoading = false;
    })
    .addCase(exportCsvFailure, (state) => {
      state.exportData.isLoading = false;
    });
});

export default reducer;
