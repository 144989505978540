import {
  all,
  fork,
  put,
  call,
  takeLatest,
  select,
  take,
  takeLeading,
} from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { addAlert } from 'store/notify';
import API, { CancelToken, Canceler } from 'services/defaultInstance';
import { nanoid } from '@reduxjs/toolkit';
import * as actions from './index';
import {
  GetPurchasesResponse,
  CreatePayPurchase,
  GetWalletAccountsResponse,
  GetSummariesPurchasesResponse,
  GetPurchaseResponse,
  PaymentTransportFee,
  PurchasesQueries,
  DefaultQueries,
} from 'models';
import { findErrorToData, setPageInfo } from 'utils';
import { RootState } from 'store/index';

let cancels: Canceler[] = [];

const payPurchaseAPI = (transaction: CreatePayPurchase) =>
  API.post(`/v1/purchase/transactions-payment`, transaction);
const completePurchaseAPI = (data: any) =>
  API.post(`/v1/purchase/transactions-complete`, data);
const getWalletAccountsAPI = (tierName: string) =>
  API.get(`/v1/tiers/${tierName}/wallets`, {
    cancelToken: new CancelToken((c) => cancels.push(c)),
  });
const getSummariesPurchasesAPI = (query: string) =>
  API.get(`/v1/purchase/transactions-previewsummaries?${query}`);
const createSummariesPurchasesAPI = (tierName: string, data: any) =>
  API.post(`/v1/purchase/transactions-summaries`, {
    tier: tierName,
    ...data,
  });

const getPurchaseAPI = (transactionId: string) =>
  API.get(`/v1/purchase/transactions/${transactionId}`);

type GetPurchasesRequest = ReturnType<typeof actions.getPurchasesRequest>;
function* getPurchases({ payload }: GetPurchasesRequest) {
  const {
    tierPurchases: { purchases },
  }: RootState = yield select((state) => state);
  const { page, pageSize, startedAt, endedAt, ...payloadParams } = payload;
  let params: PurchasesQueries & DefaultQueries = {
    ...payloadParams,
    pageSize: pageSize || purchases.pageSize,
  };
  if (startedAt && !endedAt) {
    params.createdAt = startedAt;
  }
  if (startedAt && endedAt) {
    params.startedAt = startedAt;
    params.endedAt = endedAt;
  }
  try {
    const { data }: GetPurchasesResponse = yield call(
      API.get,
      '/v1/purchase/transactions',
      {
        params,
        cancelToken: new CancelToken((c) => cancels.push(c)),
      }
    );
    const page = setPageInfo(
      {
        page: purchases.page,
        pageSize: purchases.pageSize,
        pageTokens: purchases.pageTokens,
      },
      {
        page: payload.page,
        pageSize: payload.pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    const dataPayload: actions.GetPurchasesSuccess = {
      ...page,
      data: data.transactions,
    };
    cancels = [];
    yield put(actions.getPurchasesSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    if (errorData) {
      yield put(actions.getPurchasesFailure(errorData.message));
    }
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type PurchasesOnChangePageAction = ReturnType<
  typeof actions.purchasesOnChangePage
>;
function* purchasesOnChangePage({ payload }: PurchasesOnChangePageAction) {
  const {
    purchases: { pageTokens, page },
  }: actions.InitialState = yield select((state) => state.tierPurchases);
  let cloneFilters: any = {
    ...payload,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  cloneFilters.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    cloneFilters.pageToken = pageToken;
  }
  yield put(actions.getPurchasesRequest(cloneFilters));
}
type PurchasesOnChangePageSize = ReturnType<
  typeof actions.purchasesOnChangePageSize
>;
function* purchasesOnChangePageSize({ payload }: PurchasesOnChangePageSize) {
  yield put(actions.getPurchasesRequest(payload));
}

type GetWalletAccountsAction =
  | ReturnType<typeof actions.getWalletAccountsFailure>
  | ReturnType<typeof actions.getWalletAccountsSuccess>;
type OpenPayPurchaseFormAction = ReturnType<
  typeof actions.openPayPurchaseFormRequest
>;
function* openPayPurchaseForm({ payload }: OpenPayPurchaseFormAction) {
  const { form }: actions.InitialState = yield select(
    (state) => state.tierPurchases
  );
  if (form.walletAccounts.length === 0) {
    yield put(actions.getWalletAccountsRequest());
    const walletAccountsAction: GetWalletAccountsAction = yield take([
      actions.getWalletAccountsFailure,
      actions.getWalletAccountsSuccess,
    ]);
    if (walletAccountsAction.type === actions.Types.getWalletAccountsFailure) {
      yield put(
        actions.openPayPurchaseFormFailure(walletAccountsAction.payload)
      );
      return;
    }
  }
  yield put(actions.openPayPurchaseFormSuccess(payload));
}
type PayPurchaseAction = ReturnType<typeof actions.payPurchaseRequest>;
function* payPurchase({ payload }: PayPurchaseAction) {
  const {
    tierPurchases: { form },
  }: RootState = yield select((state) => state);
  try {
    if (payload.type === 'pay') {
      let transaction: CreatePayPurchase = {
        accountNumber: payload.accountNumber || '',
        transactionId: form.purchase?.id || '',
        referenceId: nanoid(18),
      };
      yield call(payPurchaseAPI, transaction);
    } else {
      let transaction = {
        transactionId: form.purchase?.id || '',
      };
      yield call(completePurchaseAPI, transaction);
    }
    yield put(actions.payPurchaseSuccess());
    yield put(
      addAlert({
        message: 'ການຊຳລະເງິນສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.payPurchaseFailure(errorData ? errorData.message : null));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getWalletAccounts() {
  const {
    auth: { accept },
  }: RootState = yield select((state) => state);
  try {
    const { data }: GetWalletAccountsResponse = yield call(
      getWalletAccountsAPI,
      accept.tier?.name || ''
    );
    yield put(actions.getWalletAccountsSuccess(data.wallets));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(
      actions.getWalletAccountsFailure(errorData ? errorData.message : null)
    );
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type OpenPreviewSummaryRequest = ReturnType<
  typeof actions.openPreviewSummaryRequest
>;
function* openPreviewSummary({ payload }: OpenPreviewSummaryRequest) {
  const {
    auth: { accept },
  }: RootState = yield select((state) => state);
  const query = `tier=${accept.tier?.name}&purchasedAt=${encodeURIComponent(
    payload
  )}`;
  try {
    const { data }: GetSummariesPurchasesResponse = yield call(
      getSummariesPurchasesAPI,
      query
    );
    if (data.summaries.length === 0) {
      yield put(
        addAlert({
          message: 'ບໍ່ມີລາຍການປິດການຮັບຊື້ສິນຄ້າ',
          serviceType: 'snackbar',
          type: 'warning',
        })
      );
    }
    yield put(actions.openPreviewSummarySuccess(data.summaries));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    if (errorData && errorData.message === 'ປິດການຮັບຊື້ສຳເລັດແລ້ວ') {
      yield put(actions.openPreviewSummarySuccess([]));
    } else {
      yield put(
        actions.openPreviewSummaryFailure(errorData ? errorData.message : null)
      );
    }
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type CreatePurchasesSummaryAction = ReturnType<
  typeof actions.createSummariesPurchasesRequest
>;
function* createPurchasesSummary({ payload }: CreatePurchasesSummaryAction) {
  const {
    auth: { accept },
    tierPurchases: {
      form: { summaries },
    },
  }: RootState = yield select((state) => state);
  try {
    const firstItem = summaries[0];
    if (!firstItem) {
      yield put(actions.createSummariesPurchasesFailure(''));
      return;
    }
    yield call(createSummariesPurchasesAPI, accept.tier?.name || '', {
      ...payload,
      purchasedAt: firstItem.purchasedAt,
    });
    yield put(actions.createSummariesPurchasesSuccess());
    yield put(
      addAlert({
        message: 'ປີດການຊື້ສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(
      actions.createSummariesPurchasesFailure(
        errorData ? errorData.message : null
      )
    );
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type GetPurchaseRequestAction = ReturnType<typeof actions.getPurchaseRequest>;
function* getPurchase({ payload }: GetPurchaseRequestAction) {
  try {
    const { data }: GetPurchaseResponse = yield call(getPurchaseAPI, payload);
    yield put(actions.getPurchaseSuccess(data.transaction));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getPurchaseFailure(errorData ? errorData.message : null));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* cancelPurchase() {
  const { form }: actions.InitialState = yield select(
    (state) => state.tierPurchases
  );
  try {
    yield call(API.post, `/v1/purchase/transactions-cancel`, {
      transactionId: form.purchase?.id || '',
    });
    yield put(actions.cancelPurchaseSuccess());
    yield put(
      addAlert({
        message: 'ຍົກເລີກການຊື້ສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(
      actions.cancelPurchaseFailure(errorData ? errorData.message : null)
    );
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeDialogId({
  payload,
}: ReturnType<typeof actions.onChangeDialogID>) {
  const {
    auth: {
      accept: { resourceAccess },
    },
    tierPurchases: {
      form: { walletAccounts },
    },
  }: RootState = yield select((state) => state);
  if (
    payload === 'paymentTransportFee' &&
    resourceAccess['purchases'].create &&
    walletAccounts.length === 0
  ) {
    yield put(actions.getWalletAccountsRequest());
  }
}
function* paymentTransport({
  payload,
}: ReturnType<typeof actions.paymentTransportRequest>) {
  const {
    form: { walletAccounts, purchase },
  }: actions.InitialState = yield select((state) => state.tierPurchases);
  const account = walletAccounts.find(
    (account) => account.number === payload.accountNumber
  );
  const fee: PaymentTransportFee = {
    referenceId: nanoid(18),
    accountNumber: payload.accountNumber,
    description: payload.description,
    transactionId: purchase?.id || '',
    transportFee: {
      amount: payload.amount,
      currency: account?.currency || 'LAK',
    },
  };
  try {
    yield call(API.post, `/v1/purchase/transactions-paymentTransportFee`, fee);
    yield put(actions.paymentTransportSuccess(fee));
    yield put(
      addAlert({
        message: 'ຈ່າຍຄ່າຂົນສົ່ງສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(
      actions.paymentTransportFailure(errorData ? errorData.message : '')
    );
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* exportCsv({ payload }: ReturnType<typeof actions.exportCsvRequest>) {
  try {
    const { page, pageSize, startedAt, endedAt, ...payloadParams } = payload;
    let params: PurchasesQueries & DefaultQueries = {
      ...payloadParams,
    };
    if (startedAt && !endedAt) {
      params.createdAt = startedAt;
    }
    if (startedAt && endedAt) {
      params.startedAt = startedAt;
      params.endedAt = endedAt;
    }

    const { data } = yield call(
      API.post,
      `/v1/purchase/transactions-exportCsv`,
      params
    );
    const blob = new Blob(['\uFEFF' + data], {
      type: 'text/csv; charset=utf-8',
    });
    yield saveAs(
      blob,
      `ລາຍການຮັບຊື້ສິນຄ້າ${moment().format('DD/MM/YYYY')}.csv`
    );
    yield put(actions.exportCsvSuccess());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.exportCsvFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}

function* watchGetPurchases() {
  yield takeLatest(actions.Types.getPurchasesRequest, getPurchases);
}
function* watchPurchasesOnChangePage() {
  yield takeLatest(actions.Types.purchasesOnChangePage, purchasesOnChangePage);
}
function* watchPurchasesOnChangePageSize() {
  yield takeLatest(
    actions.Types.purchasesOnChangePageSize,
    purchasesOnChangePageSize
  );
}

function* watchOpenPayPurchaseForm() {
  yield takeLatest(
    actions.Types.openPayPurchaseFormRequest,
    openPayPurchaseForm
  );
}
function* watchPayPurchase() {
  yield takeLatest(actions.Types.payPurchaseRequest, payPurchase);
}
function* watchGetWalletAccounts() {
  yield takeLatest(actions.Types.getWalletAccountsRequest, getWalletAccounts);
}
function* watchOpenPreviewSummary() {
  yield takeLatest(actions.Types.openPreviewSummaryRequest, openPreviewSummary);
}
function* watchCreatePurchasesSummary() {
  yield takeLatest(
    actions.Types.createSummariesPurchasesRequest,
    createPurchasesSummary
  );
}
function* watchGetPurchase() {
  yield takeLatest(actions.Types.getPurchaseRequest, getPurchase);
}
function* watchCancelPurchase() {
  yield takeLatest(actions.Types.cancelPurchaseRequest, cancelPurchase);
}
function* watchOnChangeDialogId() {
  yield takeLatest(actions.Types.onChangeDialogID, onChangeDialogId);
}
function* watchPaymentTransport() {
  yield takeLeading(actions.Types.paymentTransportRequest, paymentTransport);
}
function* watchExportCsv() {
  yield takeLatest(actions.Types.exportCsvRequest, exportCsv);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    // fork(watchOpenForm),
    fork(watchGetPurchases),
    fork(watchPurchasesOnChangePage),
    fork(watchPurchasesOnChangePageSize),
    fork(watchOpenPayPurchaseForm),
    fork(watchPayPurchase),
    fork(watchGetWalletAccounts),
    fork(watchOpenPreviewSummary),
    fork(watchCreatePurchasesSummary),
    fork(watchGetPurchase),
    fork(watchCancelPurchase),
    fork(watchOnChangeDialogId),
    fork(watchPaymentTransport),
    fork(watchExportCsv),
  ]);
}
export default saga;
