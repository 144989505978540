import { all, fork } from 'redux-saga/effects';
import productPrice from './product-price/saga';
import wallet from './wallets/saga';
import purchases from './purchases/saga';
import qualityCheck from './qualityCheck/saga';
import purchasesSummaries from './purchasesSummaries/saga';
import stocks from './inventory/stocks/saga';
import lots from './inventory/lots/saga';
import stocksTransform from './inventory/stocks-transform/saga';
import saleOrders from './sale/orders/saga';
import saleTransactions from './sale/transactions/saga';
import customers from './customers/saga';
import invoices from './sale/invoices/saga';
import transfers from './sale/transfers/saga';
import receipts from './sale/receipts/saga';
import rivalPurchasePrices from './rivalPurchasePrices/saga';
import expenses from './expenses/saga';
export default function* sagas() {
  yield all([
    fork(productPrice),
    fork(wallet),
    fork(purchases),
    fork(qualityCheck),
    fork(purchasesSummaries),
    fork(stocks),
    fork(lots),
    fork(stocksTransform),
    fork(saleOrders),
    fork(saleTransactions),
    fork(customers),
    fork(invoices),
    fork(transfers),
    fork(receipts),
    fork(rivalPurchasePrices),
    fork(expenses),
  ]);
}
