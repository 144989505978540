import { createReducer, createAction, nanoid } from '@reduxjs/toolkit';
import {
  DefaultQueries,
  StocksTransformFilter,
  Stock,
  StockQualityCheck,
  StockQualityCheckForm,
} from 'models';
import moment from 'moment';

type DialogID = null | 'confirmTransform' | 'qualityCheckForm';

export interface InitialState {
  list: {
    stocks: Stock[];
    page: number;
    pageSize: number;
    pageTokens: string[];
    isLoading: boolean;
    error: string | null;
  };
  dialogID: DialogID;
  get: {
    isLoading: boolean;
    error: string | null;
    isCreating: boolean;
    stock: Stock | null;
    qualityChecks: StockQualityCheck[];
  };
}
const initialState: InitialState = {
  list: {
    stocks: [],
    page: 1,
    pageSize: 25,
    pageTokens: [],
    isLoading: false,
    error: null,
  },
  dialogID: null,
  get: {
    isLoading: false,
    error: null,
    isCreating: false,
    qualityChecks: [],
    stock: null,
  },
};
export enum Types {
  cancelRequestAPI = 'tier/inventory/stocks-transform@cancelRequestAPI',
  onChangeDialogID = 'tier/inventory/stocks-transform@onChangeDialogID',

  getStocksRequest = 'tier/inventory/stocks-transform@getStocksRequest',
  getStocksSuccess = 'tier/inventory/stocks-transform@getStocksSuccess',
  getStocksFailure = 'tier/inventory/stocks-transform@getStocksFailure',

  createQualityCheckRequest = 'tier/inventory/stocks-transform@createQualityCheckRequest',
  createQualityCheckSuccess = 'tier/inventory/stocks-transform@createQualityCheckSuccess',
  createQualityCheckFailure = 'tier/inventory/stocks-transform@createQualityCheckFailure',
  getStockRequest = 'tier/inventory/stocks-transform@getStockRequest',
  getStockSuccess = 'tier/inventory/stocks-transform@getStockSuccess',
  getStockFailure = 'tier/inventory/stocks-transform@getStockFailure',
  getQualityChecksSuccess = 'tier/inventory/stocks-transform@getQualityChecksSuccess',
  getQualityChecksFailure = 'tier/inventory/stocks-transform@getQualityChecksFailure',
  createCompleteTransformRequest = 'tier/inventory/stocks-transform@createCompleteTransformRequest',
  createCompleteTransformSuccess = 'tier/inventory/stocks-transform@createCompleteTransformSuccess',
  createCompleteTransformFailure = 'tier/inventory/stocks-transform@createCompleteTransformFailure',
}

export type GetStocksSuccess = DefaultQueries & {
  stocks: Stock[];
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const onChangeDialogID = createAction<DialogID>(Types.onChangeDialogID);
export const getStocksRequest = createAction<StocksTransformFilter>(
  Types.getStocksRequest
);
export const getStocksSuccess = createAction<GetStocksSuccess>(
  Types.getStocksSuccess
);
export const getStocksFailure = createAction<string>(Types.getStocksFailure);
export const createQualityCheckRequest = createAction<StockQualityCheckForm>(
  Types.createQualityCheckRequest
);
export const createQualityCheckSuccess = createAction<StockQualityCheckForm>(
  Types.createQualityCheckSuccess
);
export const createQualityCheckFailure = createAction<string>(
  Types.createQualityCheckFailure
);
export const getStockRequest = createAction<string>(Types.getStockRequest);
export const getStockSuccess = createAction<Stock>(Types.getStockSuccess);
export const getStockFailure = createAction<string>(Types.getStockFailure);
export const getQualityChecksSuccess = createAction<StockQualityCheck[]>(
  Types.getQualityChecksSuccess
);
export const getQualityChecksFailure = createAction<string>(
  Types.getQualityChecksFailure
);
export const createCompleteTransformRequest = createAction<string>(
  Types.createCompleteTransformRequest
);
export const createCompleteTransformSuccess = createAction(
  Types.createCompleteTransformSuccess
);
export const createCompleteTransformFailure = createAction<string>(
  Types.createCompleteTransformFailure
);

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    state.list.isLoading = false;
    state.dialogID = null;
    state.get.isLoading = false;
    state.get.isCreating = false;
  });
  builder.addCase(onChangeDialogID, (state, action) => {
    state.dialogID = action.payload;
  });
  builder.addCase(getStocksRequest, (state) => {
    state.list.isLoading = true;
    state.list.error = null;
  });
  builder.addCase(getStocksSuccess, (state, action) => {
    return {
      ...state,
      list: {
        ...state.list,
        ...action.payload,
        isLoading: false,
      },
    };
  });
  builder.addCase(getStocksFailure, (state, action) => {
    state.list.isLoading = false;
    state.list.error = action.payload;
  });
  builder.addCase(createQualityCheckRequest, (state) => {
    state.get.isCreating = true;
    state.get.error = null;
  });
  builder.addCase(createQualityCheckSuccess, (state, { payload }) => {
    state.get.isCreating = false;
    state.dialogID = null;
    state.get.qualityChecks = [
      ...state.get.qualityChecks,
      {
        ...payload,
        createdAt: moment().format(),
        id: nanoid(10),
        stockId: state.get.stock?.id || '',
      },
    ];
  });
  builder.addCase(createQualityCheckFailure, (state, action) => {
    state.get.isCreating = false;
    state.get.error = action.payload;
  });
  builder.addCase(getStockRequest, (state) => {
    state.get.isLoading = true;
    state.get.error = null;
  });
  builder.addCase(getStockSuccess, (state, action) => {
    state.get.stock = action.payload;
  });
  builder.addCase(getStockFailure, (state, action) => {
    state.get.isLoading = false;
    state.get.error = action.payload;
  });
  builder.addCase(getQualityChecksSuccess, (state, action) => {
    state.get.qualityChecks = action.payload;
    state.get.isLoading = false;
  });
  builder.addCase(getQualityChecksFailure, (state, action) => {
    state.get.isLoading = false;
    state.get.error = action.payload;
  });
  builder.addCase(createCompleteTransformRequest, (state) => {
    state.get.isCreating = true;
    state.get.error = null;
  });
  builder.addCase(createCompleteTransformSuccess, (state) => {
    state.get.isCreating = false;
    state.dialogID = null;
  });
  builder.addCase(createCompleteTransformFailure, (state, action) => {
    state.get.isCreating = false;
    state.get.error = action.payload;
  });
});

export default reducer;
